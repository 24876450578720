<template>
  <aside class="p-md-2">
    <div class="offcanvas-md offcanvas-end" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel"
      data-bs-scroll="true">
      <div class="offcanvas-header py-2 border-bottom">
        <h5 class="offcanvas-title" id="sidebarMenuLabel">Sistem Informasi Data Siswa</h5>
        <button type="button" class="btn-close" id="btnClose" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
          aria-label="Close"></button>
      </div>
      <div class="offcanvas-body d-flex flex-column p-2 p-md-0 overflow-y-auto">
        <ul class="nav flex-column gap-1">
          <li class="nav-item">
            <router-link to="/" class="nav-link rounded" :active-class="'active'" @click="tutupOffcanvas">
              Dasbor
            </router-link>
          </li>
          <li class="nav-item" v-if="kesiswaan || bp">
            <router-link to="/data-siswa" class="nav-link rounded" :active-class="'active'" @click="tutupOffcanvas">
              Semua Siswa
            </router-link>
          </li>
          <li class="nav-item" v-if="kesiswaan || bp">
            <a href="#" class="nav-link rounded btn-toggle d-flex justify-content-between align-items-center collapsed"
              :class="{ 'btn-toggle-active': $route.name === 'rombel' || $route.name === 'anggotaRombel' || $route.name === 'dataAnggotaRombel' }"
              data-bs-toggle="collapse" data-bs-target="#rombel-collapse" aria-expanded="false">
              Rombongan Belajar
            </a>
            <div class="collapse" id="rombel-collapse">
              <ul class="btn-toggle-nav nav flex-column gap-1 small mt-1">
                <li class="nav-item" v-for="r in listRombel" :key="r">
                  <router-link :to="'/rombel/' + r.rombel_id" class="nav-link rounded" :active-class="'active'"
                    @click="tutupOffcanvas">{{ r.nama }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" v-if="rombelWali">
            <a href="#" class="nav-link rounded btn-toggle d-flex justify-content-between align-items-center collapsed"
              :class="{ 'btn-toggle-active': $route.name === 'dataSiswaWalas' }" data-bs-toggle="collapse"
              data-bs-target="#siswa-collapse" aria-expanded="false">
              Data Siswa {{ rombelWali.nama }}
            </a>
            <div class="collapse" id="siswa-collapse">
              <ul class="btn-toggle-nav nav flex-column gap-1 small mt-1">
                <li class="nav-item" v-for="s in listSiswa" :key="s">
                  <router-link
                    :to="$route.params.siswa_id == s.siswa_id ? '/data-siswa-walas/' + s.siswa_id + '/' + $route.params.data : '/data-siswa-walas/' + s.siswa_id + '/detail'"
                    class="nav-link rounded" :active-class="'active'" @click="tutupOffcanvas">{{ s.nama }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" v-if="siswa">
            <router-link to="/siswa/detail" class="nav-link rounded" :active-class="'active'" @click="tutupOffcanvas">
              Data Rinci Siswa
            </router-link>
          </li>
          <li class="nav-item" v-if="siswa">
            <router-link to="/siswa/data-ayah" class="nav-link rounded" :active-class="'active'"
              @click="tutupOffcanvas">
              Data Ayah
            </router-link>
          </li>
          <li class="nav-item" v-if="siswa">
            <router-link to="/siswa/data-ibu" class="nav-link rounded" :active-class="'active'" @click="tutupOffcanvas">
              Data Ibu
            </router-link>
          </li>
          <li class="nav-item" v-if="siswa">
            <router-link to="/siswa/data-wali" class="nav-link rounded" :active-class="'active'"
              @click="tutupOffcanvas">
              Data Wali
            </router-link>
          </li>
          <div class="hstack gap-2 border-top border-secondary-subtle pt-3 my-2">
            <img v-if="user.foto" :src="user.foto" width="40" alt=""
              class="rounded-circle border border-1 border-secondary">
            <img v-else src="../assets/person-light.png" width="40" alt=""
              class="rounded-circle border border-1 border-secondary">
            <div class="vr"></div>
            <div class="d-flex flex-column sidebar-heading my-auto text-body-secondary text-break small">
              <h6 class="fst-normal m-0">{{ user.nama }}</h6>
              <span v-if="user.nis">{{ user.nis }}<br /></span>
              <small class="fst-italic">{{ user.username }}</small>
            </div>
          </div>
          <li class="nav-item">
            <router-link to="/pengaturan" v-if="kesiswaan || bp || walas || siswa" class="nav-link rounded"
              :active-class="'active'" @click="tutupOffcanvas">
              Pengaturan
            </router-link>
          </li>
          <li class="nav-item">
            <button type="button" class="nav-link w-100 btn text-start" @click="logout">
              Keluar
            </button>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      kesiswaan: false,
      bp: false,
      walas: false,
      siswa: false,
      rombelWali: null,
      listRombel: [],
      listSiswa: [],
    }
  },
  props: {
    user: Object
  },
  computed: {
    token() {
      return this.$store.getters.getToken;
    },
  },
  mounted() {
    if (this.user.role === 'siswa') {
      this.siswa = true;
    } else {
      this.kesiswaan = String(this.user.ket).split(",").some((k) => {
        return ["it", "kesiswaan"].includes(k);
      });
      this.bp = String(this.user.ket).split(",").some((k) => {
        return ["it", "kesiswaan", "waka", "bp"].includes(k);
      });
      this.walas = String(this.user.ket).split(",").some((k) => {
        return k.trim() === "walas";
      });
      if (this.bp) {
        this.getListRombel();
      }
      if (this.walas) {
        this.getRombelWali();
      }
    }
  },
  methods: {
    async getListRombel() {
      try {
        const response = await this.axios.post('/data-siswa/rombel/list', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listRombel = response.data.listRombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRombelWali() {
      try {
        const response = await this.axios.post('/kurikulum/rombel/walas', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.rombelWali = response.data.rombelWali;
          this.getAnggotaRombel();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getAnggotaRombel() {
      try {
        const response = await this.axios.post('/data-siswa/anggota-rombel/list', {
          rombel_id: this.rombelWali.rombel_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listSiswa = response.data.listAnggotaRombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async logout() {
      try {
        const response = await this.axios.post(this.$auth + '/logout', {},
          { headers: { Authorization: this.token } });
        if (response) {
          this.$store.dispatch('logoutUser');
          window.location.href = this.$sso + "/" + this.$appId;
        }
      } catch (err) {
        this.$store.dispatch('logoutUser');
        window.location.href = this.$sso + '/' + this.$appId
      }
    },
    tutupOffcanvas() {
      document.getElementById("btnClose").click();
    }
  }
}
</script>

<style scoped>
.nav-link,
.nav-link:focus,
.nav-link:active {
  color: #000;
  transition: padding 0.35s ease;
}


.nav-link:hover {
  color: #000;
  background-color: #184b8945;
  padding-left: calc(var(--bs-nav-link-padding-x) + 0.5rem);
}

.nav-link.active {
  font-weight: bold;
  color: #fff;
  background-color: #184b89;
}

.btn-toggle[aria-expanded="true"] {
  box-shadow: 0 5px 10px #184b8945;
}

.btn-toggle-active {
  background-color: #184b8925;
}

.btn-toggle-nav .nav-link {
  padding: calc(var(--bs-nav-link-padding-y) - 0.2rem) var(--bs-nav-link-padding-x) calc(var(--bs-nav-link-padding-y) - 0.2rem) calc(var(--bs-nav-link-padding-x) + 1rem);
}

.btn-toggle-nav .nav-link:hover {
  padding-left: calc(var(--bs-nav-link-padding-x) + 1.5rem);
}

.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(90deg);
}

.btn-toggle::after {
  width: 1rem;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}
</style>