<template>
	<div v-if="user.role !== 'siswa'">
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
			<h1 class="h2 text-primary-emphasis">Data Siswa</h1>
		</div>
		<div class="card shadow my-3">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<router-link :to="'detail'" class="nav-link" active-class="active">Data Rinci Siswa</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'data-ayah'" class="nav-link" active-class="active">Data Ayah </router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'data-ibu'" class="nav-link" active-class="active">Data Ibu </router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'data-wali'" class="nav-link" active-class="active">Data Wali </router-link>
					</li>
				</ul>
			</div>
			<detail-siswa v-if="$route.params.data == 'detail'" class="card-body" />
			<data-ayah v-else-if="$route.params.data == 'data-ayah'" class="card-body" />
			<data-ibu v-else-if="$route.params.data == 'data-ibu'" class="card-body" />
			<data-wali v-else-if="$route.params.data == 'data-wali'" class="card-body" />
			<not-found v-else class="card-body" />
		</div>
	</div>
	<div v-else>
		<detail-siswa v-if="$route.params.data == 'detail'" />
		<data-ayah v-else-if="$route.params.data == 'data-ayah'" />
		<data-ibu v-else-if="$route.params.data == 'data-ibu'" />
		<data-wali v-else-if="$route.params.data == 'data-wali'" />
		<not-found v-else />
	</div>
</template>

<script>
import DetailSiswa from "@/components/DetailSiswa.vue";
import DataAyah from "@/components/DataAyah.vue";
import DataIbu from "@/components/DataIbu.vue";
import DataWali from "@/components/DataWali.vue";
import NotFound from "@/components/NotFound.vue";
export default {
	name: "DataSiswaView",
	components: {
		DetailSiswa,
		DataAyah,
		DataIbu,
		DataWali,
		NotFound,
	},
	computed: {
		user() {
			return this.$store.getters.getUserData;
		},
	},
};
</script>

<style></style>
