<template>
  <svg viewBox="0 0 150 150" class="circular-progress" :style="{ '--ukuran': progress }">
    <circle cx="50%" cy="50%" r="42.5%" class="bg"></circle>
    <circle cx="50%" cy="50%" r="42.5%" class="fg" :stroke="warna"></circle>
    <text x="50%" y="50%" :font-size="progress == 100 ? '200%' : '100%'" text-anchor="middle"
      :fill="progress == 0 ? '#000' : warna" dominant-baseline="middle">
      {{ progress == 100 ? "✔" : progress + '%' }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "CircularProgress",
  date() {
    return {
    }
  },
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
  },
  computed: {
    warna() {
      if (this.progress == 0) {
        return '#ddd';
      } else if (this.progress < 33) {
        return 'red';
      } else if (this.progress < 66) {
        return 'orange';
      } else if (this.progress < 100) {
        return '#5394fd';
      } else {
        return 'green'
      }
    }
  }
};
</script>

<style scoped>
.circular-progress {
  --size: 100%;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 15%;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation 1s linear 0s 1 forwards;
}

.circular-progress circle {
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: #ddd;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@keyframes progress-animation {
  from {
    --progress: 0;
  }

  to {
    --progress: var(--ukuran);
  }
}
</style>
