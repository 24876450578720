<template>
  <dasbor-siswa v-if="user.role === 'siswa'" />
  <div v-if="kesiswaan || bp">
    <dasbor-kontrol />
    <hr v-if="walas" />
  </div>
  <dasbor-walas v-if="walas" />
</template>

<script>
import DasborKontrol from '@/components/DasborKontrol'
import DasborWalas from '@/components/DasborWalas'
import DasborSiswa from '@/components/DasborSiswa'

export default {
  name: 'HomeView',
  components: {
    DasborKontrol,
    DasborWalas,
    DasborSiswa,
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    kesiswaan() {
      return String(this.user.ket).split(",").some((k) => {
        return ["it", "kesiswaan"].includes(k);
      })
    },
    bp() {
      return String(this.user.ket).split(",").some((k) => {
        return ["it", "kesiswaan", "waka", "bp"].includes(k);
      })
    },
    walas() {
      return String(this.user.ket).split(",").some((k) => {
        return k.trim() === "walas";
      })
    },
  },
}
</script>
