<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
    <h1 class="h2 text-primary-emphasis">Pengaturan</h1>
  </div>
  <div class="card shadow my-3">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-center">
        <div class="col-auto" style="position:relative;">
          <img v-if="user.foto" :src="user.foto" width="160" alt=""
            class="rounded-circle mx-auto d-block img-thumbnail shadow">
          <img v-else src="../assets/person-light.png" width="160" alt=""
            class="rounded-circle mx-auto d-block img-thumbnail shadow">
          <button v-if="user.role !== 'siswa'"
            class="btn btn-sm btn-link hstack gap-1 bg-light bg-opacity-75 border-1 border-secondary-subtle"
            style="position: absolute; bottom: 0; right: 0;" data-bs-toggle="modal" data-bs-target="#unggahFoto">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-camera-fill"
              viewBox="0 0 16 16">
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path
                d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
            </svg>
            <small class="text-nowrap">Ganti Foto</small></button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <legend class="card-title text-center">{{ user.nama }}</legend>
      <table class="table table-borderless card-text">
        <tbody>
          <tr>
            <td>Nama Pengguna</td>
            <td class="text-end">:</td>
            <td>{{ user.username }}</td>
          </tr>
          <tr v-if="user.role !== 'siswa'">
            <td>Peran</td>
            <td class="text-end">:</td>
            <td>{{ user.role }}</td>
          </tr>
          <tr v-else>
            <td>Nomor Induk Siswa</td>
            <td class="text-end">:</td>
            <td>{{ user.nis }}</td>
          </tr>
          <tr v-if="user.role !== 'siswa'">
            <td>Hak Akses Tambahan</td>
            <td class="text-end">:</td>
            <td>
              <span v-if="user.ket">{{ user.ket }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr v-else>
            <td>Nomor Pembayaran</td>
            <td class="text-end">:</td>
            <td>{{ user.va }}</td>
          </tr>
          <tr>
            <td>Kontak</td>
            <td class="text-end">:</td>
            <td>
              <div v-if="sedangEdit">
                <form @submit.prevent="updateKontak">
                  <input type="text" class="form-control form-control-sm mb-1" v-model="kontak"
                    :placeholder="user.kontak" required autofocus>
                  <div class="hstack gap-1">
                    <button type="submit" class="col-auto btn btn-sm btn-primary">Perbarui</button>
                    <button class="col-auto btn btn-sm btn-secondary" @click="batalkanEditKontak">Batal</button>
                  </div>
                </form>
              </div>
              <div v-else>
                {{ user.kontak }}
                <button v-if="user.role !== 'siswa'" class="btn btn-sm btn-link p-0 d-inline-flex" @click="editKontak"
                  v-tooltip="'Edit Kontak'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="15" fill="currentColor" class="bi bi-pencil-square"
                    viewBox="0 0 16 16">
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <legend class="card-title">Ganti Kata Sandi</legend>
      <div class="col-xl-10 col-xxl-8 my-3">
        <form @submit.prevent="gantiSandi" class="needs-validation" novalidate>
          <div class="row mb-2">
            <label class="col-sm-5 col-md-12 col-lg-4 col-form-label">Kata Sandi Baru</label>
            <div class="col-sm-7 col-md-12 col-lg-8">
              <input type="password" class="form-control shadow" placeholder="Kata Sandi Baru" v-model="password"
                required pattern="[A-Za-z0-9~!@#$%^&*\(\)_\-\=:;<>,.?]{8,}" @keyup="verifikasiSandi">
              <div class="invalid-feedback">
                Kata sandi setidaknya terdiri dari 8 karakter tanpa karakter <code class="mark">{}[]|\/'"</code> maupun
                spasi.</div>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-5 col-md-12 col-lg-4 col-form-label">Konfirmasi Kata Sandi Baru</label>
            <div class="col-sm-7 col-md-12 col-lg-8">
              <input type="password" class="form-control shadow" placeholder="Masukkan Ulang Kata Sandi Baru"
                v-model="verifyPassword" required :pattern="password" @keyup="verifikasiSandi">
              <div class="invalid-feedback" v-if="password">Sandi tidak sama.</div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary d-flex ms-auto shadow">Perbarui Kata Sandi</button>
        </form>
      </div>
    </div>
    <div class="modal fade" id="unggahFoto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content vl-parent" ref="formCreate">
          <form @submit.prevent="unggahFile">
            <div class="modal-header">
              <legend class="modal-title">Unggah Foto</legend>
              <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label col-form-label-sm">File Gambar</label>
                <div class="col-sm-9">
                  <input type="file" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"
                    placeholder="Pilih File Gambar" required
                    oninvalid="this.setCustomValidity('Silahkan pilih file yang akan diunggah!')"
                    oninput="this.setCustomValidity('')" ref="fileInput" @change="handleInputFile">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" id="tombol-unggah" class="btn btn-primary">Simpan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PengaturanView',
  data() {
    return {
      selectedFile: null,
      sedangEdit: false,
      kontak: null,
      password: null,
      verifyPassword: null,
      sesuai: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  created() {
  },
  methods: {
    editKontak() {
      this.sedangEdit = true;
    },
    batalkanEditKontak() {
      this.sedangEdit = false;
    },
    async updateKontak() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post(this.$auth + '/update/kontak', {
          kontak: this.kontak
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.sedangEdit = false;
            this.kontak = null;
            this.$store.dispatch('authenticateUser', this.$auth);
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Akses tidak diizinkan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    handleInputFile(event) {
      const file = event.target.files[0];
      if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
        this.selectedFile = file;
        document.getElementById('tombol-unggah').setAttribute('data-bs-dismiss', 'modal');
        return;
      } else {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file Gambar (JPG|JPEG|PNG). Silahkan pilih file dengan ekstensi yang sesuai!!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          this.$refs.fileInput.value = null;
          document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        })
      }
    },
    async unggahFile() {
      this.$refs.fileInput.value = null;
      document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
      let memuat = this.$loading.show({
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      try {
        const response = await this.axios.post(this.$auth + '/unggah/foto/user', formData, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.$store.dispatch('authenticateUser', this.$auth);
          });
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Internal Server Error',
            icon: 'error',
            confirmButtonText: 'Baik',
          })
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Akses tidak diizinkan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    verifikasiSandi() {
      const formatPassword = /[A-Za-z0-9~!@#$%^&*()_\-=:;<>,.?]{8,}/g;
      const formatSesuai = new String(this.password).match(formatPassword);
      if (formatSesuai) {
        if (this.password === this.verifyPassword) {
          this.sesuai = true;
        } else {
          this.sesuai = false;
        }
      } else {
        this.sesuai = false;
      }
    },
    async gantiSandi() {
      if (!this.sesuai) {
        const forms = document.querySelectorAll('.needs-validation')
        Array.from(forms).forEach(form => {
          form.classList.add('was-validated')
        })
      } else {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post(this.$auth + '/update/by-user', {
            password: this.password
          }, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              html: response.data.message,
              icon: 'success',
              confirmButtonText: 'Baik',
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Akses tidak diizinkan.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
    }
  }
}
</script>

<style></style>