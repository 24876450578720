<template>
	<div>
		<div v-if="user.role === 'siswa'" class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
			<h1 class="h2 text-primary-emphasis">Data Ibu</h1>
		</div>
		<div :class="{ 'card shadow my-3': user.role === 'siswa' }">
			<form @submit.prevent="updateData">
				<p v-if="user.role === 'siswa'" class="card-header">
					Progres kelengkapan data ibu :
					<strong :style="'color:' + warna">{{ persentase }}%</strong>
				</p>
				<div class="vl-parent" :class="{ 'card-body': user.role === 'siswa' }" ref="dataIbuCard">
					<div v-if="dataIbu">
						<div v-if="user.role !== 'siswa'">
							<div class="row row-cols-auto justify-content-start g-1 card-subtitle text-secondary">
								<span :class="{ 'fst-italic': !dataIbu.nis }">{{ dataIbu.nis || "nis" }}</span>
								<span>~</span>
								<span :class="{ 'fst-italic': !dataIbu.nama_siswa }">{{ dataIbu.nama_siswa || "nama_siswa" }}</span>
								<span :class="{ 'fst-italic': !dataIbu.nama_rombel }">({{ dataIbu.nama_rombel || "rombel" }})</span>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<strong>Progres kelengkapan data ibu</strong>:
								</label>
								<div class="col ps-sm-1">
									<span class="form-control-plaintext py-1 fw-bold" :style="'color:' + warna"> {{ persentase }}%</span>
								</div>
							</div>
							<hr class="my-1" />
						</div>
						<div>
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nama Lengkap Ibu</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="text" class="form-control form-control-sm shadow" :placeholder="dataIbu.nama || 'Nama Lengkap Ibu'" v-model="nama" />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.nama,
										}">
										{{ dataIbu.nama || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nomor Induk Kependudukan</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="dataIbu.nik || 'Nomor Induk Kependudukan'"
										v-model="nik"
										pattern="[0-9]{16}"
										title="Nomor Induk Kependudukan terdiri dari angka sebanyak 16 digit." />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.nik,
										}">
										{{ dataIbu.nik || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Foto KTP</span>:</label
								>
								<div class="col-auto ps-sm-1 pe-1 position-relative">
									<img v-if="dataIbu.foto_ktp" :src="dataIbu.foto_ktp" alt="Foto KTP" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
									<img v-else-if="previewFotoKtp" :src="previewFotoKtp" alt="Foto KTP" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
									<div v-else class="form-control-plaintext hstack gap-2 py-1">
										<span class="fst-italic text-danger-emphasis">belum terisi</span>
										<button
											v-if="user.role === 'admin' || user.guru_id == dataIbu.wali_kelas || user.role === 'siswa'"
											type="button"
											class="btn btn-sm btn-outline-secondary px-1 py-0 d-inline-flex"
											data-bs-toggle="modal"
											data-bs-target="#editFotoKtp"
											v-tooltip="'Unggah Foto KTP'">
											<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
												<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
												<path
													d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
											</svg>
										</button>
									</div>
									<div
										v-if="(dataIbu.foto_ktp || previewFotoKtp) && (user.role === 'admin' || user.guru_id == dataIbu.wali_kelas || user.role === 'siswa')"
										class="position-absolute top-0 end-0 m-2 p-1 hstack gap-2 bg-dark bg-opacity-75 border rounded shadow">
										<button
											type="button"
											class="btn btn-link btn-sm link-light d-inline-flex p-0"
											data-bs-toggle="modal"
											data-bs-target="#lihatFoto"
											v-tooltip="'Lihat Foto KTP'"
											@click="previewFotoKtp ? (lihatFoto = previewFotoKtp) : (lihatFoto = dataIbu.foto_ktp)">
											<svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
												<path
													fill-rule="evenodd"
													d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
											</svg>
										</button>
										<div class="vr text-light"></div>
										<button
											type="button"
											class="btn btn-sm btn-link link-light d-inline-flex p-0"
											data-bs-toggle="modal"
											data-bs-target="#editFotoKtp"
											v-tooltip="'Ganti Foto KTP'">
											<svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
												<path
													d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
												<path
													fill-rule="evenodd"
													d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
											</svg>
										</button>
									</div>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Tahun Kelahiran</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="dataIbu.tahun_lahir || 'Tahun Kelahiran'"
										v-model="tahun_lahir"
										pattern="[0-9]{4}"
										title="Tahun kelahiran terdiri dari angka sebanyak 4 digit." />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.tahun_lahir,
										}">
										{{ dataIbu.tahun_lahir || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Pendidikan</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="pendidikan"
										:placeholder="dataIbu.ket_pend || 'Pilih salah satu'"
										label="keterangan"
										:options="listPendidikan"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Pendidikan tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.ket_pend,
										}">
										{{ dataIbu.ket_pend || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Pekerjaan</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="pekerjaan"
										:placeholder="dataIbu.ket_pek || 'Pilih salah satu'"
										label="keterangan"
										:options="listPekerjaan"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Pekerjaan tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.ket_pek,
										}">
										{{ dataIbu.ket_pek || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Penghasilan per Bulan</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="penghasilan"
										:placeholder="dataIbu.ket_peng || 'Pilih salah satu'"
										label="keterangan"
										:options="listPenghasilan"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Penghasilan tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.ket_peng,
										}">
										{{ dataIbu.ket_peng || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Berkebutuhan Khusus</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="khusus"
										:placeholder="dataIbu.jenis_kebutuhan_khusus || 'Tidak ada'"
										label="nama"
										:options="listKebutuhanKhusus"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Jenis kebutuhan khusus tidak ditemukan.</template>
									</v-select>
									<span class="form-text fst-italic">Abaikan atau silakan pilih "Tidak ada" jika tidak memiliki kebutuhan khusus.</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span class="form-control-plaintext py-1">{{ dataIbu.jenis_kebutuhan_khusus || "Tidak ada" }}</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Kontak</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="dataIbu.kontak || 'Nomor Telepon (WhatsApp)'"
										v-model="kontak"
										pattern="[0-9]{10,13}"
										title="Nomor telepon terdiri dari angka sebanyak 10 hingga 13 digit." />
									<span class="form-text fst-italic">Masukkan nomor telepon yang dapat dihubungi melalui aplikasi WhatsApp maupun seluler.</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span class="form-control-plaintext py-1" :class="{ 'fst-italic text-danger-emphasis': !dataIbu.kontak }">
										{{ dataIbu.kontak || "belum terisi" }}
									</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Alamat</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="dataIbu.alamat || 'Nama jalan, nomor, gang, dusun, dsb. (Alamat pelengkap)'"
										v-model="alamat" />
									<div class="row gx-1 gy-2 pt-1 small">
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RT :</label>
											<input type="number" class="form-control form-control-sm shadow" :placeholder="dataIbu.rt || 'RT'" v-model="rt" />
										</div>
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RW :</label>
											<input type="number" class="form-control form-control-sm shadow" :placeholder="dataIbu.rw || 'RW'" v-model="rw" />
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Provinsi :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="prov"
												:placeholder="dataIbu.nama_prov || 'Pilih provinsi'"
												label="nama"
												:options="listProv"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small class="px-1">Provinsi tidak ditemukan.</small>
												</template>
											</v-select>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kabupaten/Kota :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="kab_kota"
												:placeholder="dataIbu.nama_kab_kota || 'Pilih kabupaten/kota'"
												label="nama"
												:options="listKabKota"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" :required="kab_kotaRequired && !kab_kota" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small v-if="prov" class="px-1">Kabupaten/Kota tidak ditemukan.</small>
													<small v-else class="px-1">Silakan pilih provinsi terebih dahulu.</small>
												</template>
											</v-select>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kecamatan :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="kec"
												:placeholder="dataIbu.nama_kec || 'Pilih kecamatan'"
												label="nama"
												:options="listKec"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" :required="kecRequired && !kec" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small v-if="kab_kota" class="px-1">Kecamatan tidak ditemukan.</small>
													<small v-else class="px-1">Silakan pilih kabupaten/kota terebih dahulu.</small>
												</template>
											</v-select>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Desa/Kelurahan :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="desa"
												:placeholder="dataIbu.nama_desa || 'Pilih desa/kelurahan'"
												label="nama"
												:options="listDesa"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" :required="desaRequired && !desa" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small v-if="kec" class="px-1">Desa/Kelurahan tidak ditemukan.</small>
													<small v-else class="px-1">Silakan pilih kecamatan terebih dahulu.</small>
												</template>
											</v-select>
										</div>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !dataIbu.alamat,
										}">
										{{ dataIbu.alamat || "belum terisi" }}
									</span>
									<div class="row g-1 small">
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RT :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !dataIbu.rt,
												}">
												{{ dataIbu.rt || "belum terisi" }}</span
											>
										</div>
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RW :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !dataIbu.rw,
												}">
												{{ dataIbu.rw || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Desa/Kelurahan :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !dataIbu.nama_desa,
												}">
												{{ dataIbu.nama_desa || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kecamatan :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !dataIbu.nama_kec,
												}">
												{{ dataIbu.nama_kec || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kabupaten/Kota :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !dataIbu.nama_kab_kota,
												}">
												{{ dataIbu.nama_kab_kota || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Provinsi :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !dataIbu.nama_prov,
												}">
												{{ dataIbu.nama_prov || "belum terisi" }}</span
											>
										</div>
									</div>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Catatan</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<textarea class="form-control form-control-sm shadow" v-model="catatan" rows="4" :placeholder="dataIbu.catatan || 'Catatan'"></textarea>
								</div>
								<div v-else class="col ps-sm-1">
									<span class="form-control-plaintext py-1"> {{ dataIbu.catatan || "-" }}</span>
								</div>
							</div>
						</div>
						<div v-if="user.role !== 'siswa'">
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<i>Terakhir diperbarui</i>:
								</label>
								<div class="col ps-sm-1 fst-italic">
									<div class="form-control-plaintext py-1" v-if="persentase > 0">
										{{
											new Date(dataIbu.terakhir_diperbarui).toLocaleString("id-ID", {
												dateStyle: "long",
												timeStyle: "long",
											})
										}}
									</div>
									<div class="form-control-plaintext py-1" v-else>-</div>
								</div>
							</div>
						</div>
						<div v-if="user.role === 'admin' || user.role === 'siswa' || user.guru_id == dataIbu.wali_kelas" class="d-flex position-fixed fixed-bottom justify-content-end">
							<div class="position-absolute bottom-0 end-0 p-4">
								<div v-if="sedangEdit" class="btn-group btn-group-sm bg-body-tertiary bg-opacity-75 rounded shadow m-1">
									<button type="button" class="btn btn-outline-secondary px-4 shadow" @click="sedangEdit = false">Batal</button>
									<button type="submit" class="btn btn-outline-success px-4 shadow" :disabled="sedangSimpan">
										<span v-if="sedangSimpan" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
										<span v-if="sedangSimpan" role="status">Menyimpan...</span>
										<span v-else>Simpan</span>
									</button>
								</div>
								<div v-else class="bg-body-tertiary bg-opacity-75 rounded shadow m-1">
									<button type="button" class="btn btn-sm btn-outline-primary px-4 shadow" @click="sedangEdit = true">Edit Data</button>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="sedangMemuat" class="d-flex align-items-center justify-content-center gap-2">
						<div class="spinner-border border-3 text-secondary" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						<div class="text-secondary d-flex align-items-baseline gap-1">
							<span>Memuat</span>
							<div class="spinner-grow text-secondary" style="width: 0.15rem; height: 0.15rem" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<div class="spinner-grow text-secondary" style="width: 0.15rem; height: 0.15rem" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<div class="spinner-grow text-secondary" style="width: 0.15rem; height: 0.15rem" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
					<div v-else class="text-center">Data tidak ditemukan.</div>
				</div>
				<div v-if="dataIbu && user.role === 'siswa'" class="card-footer fst-italic">
					Terakhir diperbarui :
					{{
						new Date(dataIbu.terakhir_diperbarui).toLocaleString("id-ID", {
							dateStyle: "long",
							timeStyle: "long",
						})
					}}
				</div>
			</form>
		</div>
		<div class="modal fade" id="editFotoKtp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<form @submit.prevent="unggahFotoKtp">
						<div class="modal-header">
							<legend class="modal-title">Unggah Foto KTP Ibu</legend>
							<button type="button" id="tutupFormFotoKtp" class="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div class="modal-body">
							<div class="input-group input-group-sm shadow">
								<input type="file" ref="fotoKtpInput" class="form-control form-control-sm" accept="image/*" @change="handleInputFotoKtp" required :disabled="sedangInput" />
								<span class="input-group-text" v-if="sedangInput">
									<span class="spinner-border spinner-border-sm text-secondary"></span>
								</span>
							</div>
							<img v-if="previewFotoKtpInput" :src="previewFotoKtpInput" style="max-width: 100%; max-height: 140px" class="mt-1 shadow" alt="Preview" />
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-success" :disabled="sedangInput || sedangUnggah">
								<span v-if="sedangUnggah" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
								<span v-if="sedangUnggah" role="status">Menyimpan...</span>
								<span v-else>Simpan</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal fade" id="lihatFoto" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<legend class="modal-title">Lihat Foto KTP</legend>
						<button type="button" id="tutupLihatFoto" class="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div class="modal-body">
						<img v-if="lihatFoto" :src="lihatFoto" class="w-100" alt="Preview" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import imageCompression from "browser-image-compression";
import { createPopper } from "@popperjs/core";
export default {
	name: "DataIbu",
	data() {
		return {
			sedangMemuat: false,
			sedangEdit: false,
			sedangInput: false,
			sedangUnggah: false,
			sedangSimpan: false,
			listPendidikan: [],
			listPekerjaan: [],
			listPenghasilan: [],
			listKebutuhanKhusus: [],
			listProv: [],
			listKabKota: [],
			listKec: [],
			listDesa: [],
			listKabKotaAwal: [],
			listKecAwal: [],
			listDesaAwal: [],
			kab_kotaRequired: false,
			kecRequired: false,
			desaRequired: false,
			dataIbu: null,
			persentase: 0,
			previewFotoKtpInput: null,
			previewFotoKtp: null,
			fileFotoKtp: null,
			lihatFoto: null,
			nama: null,
			nik: null,
			tahun_lahir: null,
			pendidikan: null,
			pekerjaan: null,
			penghasilan: null,
			khusus: null,
			kontak: null,
			alamat: null,
			rt: null,
			rw: null,
			desa: null,
			kec: null,
			kab_kota: null,
			prov: null,
			catatan: null,
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUserData;
		},
		token() {
			return this.$store.getters.getToken;
		},
		path() {
			return this.$route.path;
		},
		warna() {
			if (this.persentase == 0) {
				return "#000";
			} else if (this.persentase < 33) {
				return "red";
			} else if (this.persentase < 66) {
				return "orange";
			} else if (this.persentase < 100) {
				return "#5394fd";
			} else {
				return "green";
			}
		},
	},
	watch: {
		path() {
			this.sedangEdit = false;
			this.getDataIbu();
		},
		sedangEdit() {
			this.$route.meta.sedangEdit = this.sedangEdit;
			document.body.scroll(0, 0);
			document.documentElement.scroll(0, 0);
			if (this.sedangEdit) {
				window.addEventListener("beforeunload", this.handleBeforeUnload);
			} else {
				window.removeEventListener("beforeunload", this.handleBeforeUnload);
			}
		},
		prov() {
			this.kab_kota = null;
			this.kab_kotaRequired = Object(this.prov).wilayah_id !== Object(this.dataIbu).prov;
			if (!this.prov) {
				this.listKabKota = this.listKabKotaAwal;
			} else {
				this.getListKabKota(this.prov.wilayah_id, 1);
			}
		},
		kab_kota() {
			this.kec = null;
			this.kecRequired = Object(this.prov).wilayah_id !== Object(this.dataIbu).prov || Object(this.kab_kota).wilayah_id !== Object(this.dataIbu).kab_kota;
			if (!this.kab_kota) {
				this.listKec = this.listKecAwal;
			} else {
				this.getListKec(this.kab_kota.wilayah_id, 1);
			}
		},
		kec() {
			this.desa = null;
			this.desaRequired =
				Object(this.prov).wilayah_id !== Object(this.dataIbu).prov ||
				Object(this.kab_kota).wilayah_id !== Object(this.dataIbu).kab_kota ||
				Object(this.kec).wilayah_id !== Object(this.dataIbu).kec;
			if (!this.kec) {
				this.listDesa = this.listDesaAwal;
			} else {
				this.getListDesa(this.kec.wilayah_id, 1);
			}
		},
	},
	mounted() {
		this.getDataIbu();
		this.getListKebutuhanKhusus();
		this.getListPendidikan();
		this.getListPekerjaan();
		this.getListPenghasilan();
		this.getListProv();
	},
	beforeUnmount() {},
	methods: {
		handleBeforeUnload(event) {
			if (this.sedangEdit) {
				event.preventDefault();
				event.returnValue = "";
			}
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList, {
				placement: "bottom",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: [0, -1],
						},
					},
					{
						name: "toggleClass",
						enabled: true,
						phase: "write",
						fn({ state }) {
							component.$el.classList.toggle("drop-up", state.placement === "top");
						},
					},
				],
			});
			return () => popper.destroy();
		},
		async getDataIbu() {
			this.dataIbu = null;
			this.sedangMemuat = true;
			try {
				const response = await this.axios.post(
					"/data-siswa/data-ibu",
					{
						siswa_id: this.$route.params.siswa_id,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					setTimeout(() => {
						let hasil = response.data.dataIbu;
						let jumlah_terisi = 0;
						if (hasil.nama) jumlah_terisi++;
						if (hasil.nik) jumlah_terisi++;
						if (hasil.tahun_lahir) jumlah_terisi++;
						if (hasil.pendidikan_id) jumlah_terisi++;
						if (hasil.pekerjaan_id) jumlah_terisi++;
						if (hasil.penghasilan_id) jumlah_terisi++;
						if (hasil.kontak) jumlah_terisi++;
						if (hasil.alamat) jumlah_terisi++;
						if (hasil.rt) jumlah_terisi++;
						if (hasil.rw) jumlah_terisi++;
						if (hasil.desa) jumlah_terisi++;
						if (hasil.kec) {
							jumlah_terisi++;
							this.getListDesa(hasil.kec);
						}
						if (hasil.kab_kota) {
							this.getListKec(hasil.kab_kota);
							jumlah_terisi++;
						}
						if (hasil.prov) {
							this.getListKabKota(hasil.prov);
							jumlah_terisi++;
						}
						if (hasil.foto_ktp) jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 15) * 100 + Number.EPSILON) * 100) / 100;
						this.dataIbu = hasil;
						let memuat = this.$loading.show({
							container: this.$refs.dataIbuCard,
							loader: "spinner",
						});
						setTimeout(() => {
							this.sedangMemuat = false;
							memuat.hide();
						}, 250);
					}, 500);
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListKebutuhanKhusus() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/berkebutuhan-khusus/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					let tidakAda = [
						{
							berkebutuhan_khusus_id: 0,
							nama: "Tidak ada",
						},
					];
					let hasil = response.data.berkebutuhan_khusus;
					this.listKebutuhanKhusus = tidakAda.concat(hasil);
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListPendidikan() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/pendidikan/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listPendidikan = response.data.pendidikan;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListPekerjaan() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/pekerjaan/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listPekerjaan = response.data.pekerjaan;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListPenghasilan() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/penghasilan/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listPenghasilan = response.data.penghasilan;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListProv() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listProv = response.data.provinsi;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListKabKota(id_prov, n) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{
						wilayah_id_prov: id_prov,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					if (!n) this.listKabKotaAwal = response.data.kab_kota;
					this.listKabKota = response.data.kab_kota;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListKec(id_kab_kota, n) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{
						wilayah_id_kab_kota: id_kab_kota,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					if (!n) this.listKecAwal = response.data.kecamatan;
					this.listKec = response.data.kecamatan;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListDesa(id_kec, n) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{
						wilayah_id_kec: id_kec,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					if (!n) this.listDesaAwal = response.data.desa;
					this.listDesa = response.data.desa;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListTempatTinggal() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/tempat-tinggal/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listTempatTinggal = response.data.tempat_tinggal;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async handleInputFotoKtp(event) {
			this.sedangInput = true;
			const file = event.target.files[0];
			if (file && file.type.startsWith("image/")) {
				const maxFileSizeMB = 10;
				const maxFileSizeByte = maxFileSizeMB * 2048 * 2048;
				if (file.size > maxFileSizeByte) {
					this.sedangInput = false;
					return this.$swal({
						title: "Peringatan",
						text: `Ukuran file tidak boleh melebihi ${maxFileSizeMB}MB!`,
						icon: "warning",
						confirmButtonText: "Mengerti",
					}).then(() => {
						this.$refs.fotoInput.value = null;
						this.previewFotoKtpInput = null;
					});
				}
				const options = {
					maxSizeMB: 2,
					maxWidthOrHeight: 2048,
					useWebWorker: true,
				};
				const compressedFile = await imageCompression(file, options);
				this.fileFotoKtp = new File([compressedFile], "foto" + compressedFile.type.replace(/image\//, "."), { type: compressedFile.type });
				setTimeout(() => {
					this.previewFotoKtpInput = URL.createObjectURL(compressedFile);
					this.sedangInput = false;
				}, 250);
			} else {
				this.$swal({
					title: "Peringatan",
					text: "File yang Anda pilih bukan file Gambar. Silahkan pilih jenis file yang sesuai!",
					icon: "warning",
					confirmButtonText: "Mengerti",
				}).then(() => {
					this.$refs.fotoKtpInput.value = null;
					this.previewFotoKtpInput = null;
					this.sedangInput = false;
				});
			}
		},
		async unggahFotoKtp() {
			this.sedangUnggah = true;
			const formData = new FormData();
			formData.append("data_ibu_id", this.dataIbu.data_ibu_id);
			formData.append("siswa_id", this.dataIbu.siswa_id);
			formData.append("foto_ktp", this.fileFotoKtp);
			try {
				const response = await this.axios.post("/data-siswa/data-ibu/perbarui-foto-ktp", formData, {
					headers: { Authorization: this.token },
				});
				if (response.data) {
					let jumlah_terisi = Math.round((this.persentase / 100) * 15);
					if (!this.dataIbu.foto_ktp) {
						jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 15) * 100 + Number.EPSILON) * 100) / 100;
					}
					if (response.data.data_ibu_id) {
						this.dataIbu.data_ibu_id = response.data.data_ibu_id;
					}
					this.$swal({
						title: "Sukses",
						text: response.data.message,
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					this.$swal({
						title: "Gagal",
						text: "Internal Server Error",
						icon: "error",
						confirmButtonText: "Baik",
					});
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$router.go();
						});
					} else if (err.response.status == 403) {
						this.$swal({
							title: "Gagal",
							text: "Akses tidak diizinkan.",
							icon: "error",
							confirmButtonText: "Baik",
						});
					} else {
						this.$swal({
							title: "Gagal",
							html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
							icon: "error",
							confirmButtonText: "Baik",
						});
					}
				} else {
					console.log(err);
				}
			}
			setTimeout(() => {
				this.previewFotoKtp = URL.createObjectURL(this.fileFotoKtp);
				this.sedangUnggah = false;
				document.getElementById("tutupFormFotoKtp").click();
			}, 1000);
		},
		async updateData() {
			if (this.nama) {
				this.nama = new String(this.nama).replace(/\s+/g, " ").trim().toUpperCase();
			}
			if (
				this.nama ||
				this.nik ||
				this.tahun_lahir ||
				this.pendidikan ||
				this.pekerjaan ||
				this.penghasilan ||
				this.khusus ||
				this.cita_cita ||
				this.kontak ||
				this.alamat ||
				this.rt ||
				this.rw ||
				this.desa ||
				this.kec ||
				this.kab_kota ||
				this.prov ||
				this.catatan !== this.dataIbu.catatan
			) {
				this.sedangSimpan = true;
				let memuat = this.$loading.show({
					container: this.$refs.dataIbuCard,
					loader: "spinner",
				});
				const newData = {
					data_ibu_id: this.dataIbu.data_ibu_id,
					siswa_id: this.dataIbu.siswa_id,
					nama: this.nama,
					nik: this.nik,
					tahun_lahir: this.tahun_lahir,
					pendidikan_id: Object(this.pendidikan).pendidikan_id,
					pekerjaan_id: Object(this.pekerjaan).pekerjaan_id,
					penghasilan_id: Object(this.penghasilan).penghasilan_id,
					berkebutuhan_khusus_id: Object(this.khusus).berkebutuhan_khusus_id,
					kontak: this.kontak,
					alamat: this.alamat,
					rt: this.rt,
					rw: this.rw,
					desa: Object(this.desa).wilayah_id,
					kec: Object(this.kec).wilayah_id,
					kab_kota: Object(this.kab_kota).wilayah_id,
					prov: Object(this.prov).wilayah_id,
					catatan: this.catatan,
				};
				try {
					const response = await this.axios.post("/data-siswa/data-ibu/perbarui", newData, {
						headers: { Authorization: this.token },
					});
					if (response.data) {
						this.$swal({
							title: "Sukses",
							text: response.data.message,
							icon: "success",
							showConfirmButton: false,
							timer: 1500,
						}).then(() => {
							this.nama = null;
							this.nik = null;
							this.tahun_lahir = null;
							this.pendidikan = null;
							this.pekerjaan = null;
							this.penghasilan = null;
							this.khusus = null;
							this.kontak = null;
							this.alamat = null;
							this.rt = null;
							this.rw = null;
							this.desa = null;
							this.kec = null;
							this.kab_kota = null;
							this.prov = null;
							this.catatan = null;
							this.kab_kotaRequired = false;
							this.kecRequired = false;
							this.desaRequired = false;
						});
					}
				} catch (err) {
					if (err.response) {
						if (err.response.status == 401) {
							this.$swal({
								title: "Gagal",
								text: "Sesi berakhir. Silahkan login ulang.",
								icon: "error",
								confirmButtonText: "Baik",
							}).then(() => {
								this.$store.dispatch("logoutUser");
								window.location.href = this.$sso + "/" + this.$appId;
							});
						} else {
							console.log(err);
							this.$swal({
								title: "Gagal",
								html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
								icon: "error",
								confirmButtonText: "Baik",
							});
						}
					} else {
						console.log(err);
					}
				}
				setTimeout(() => {
					this.sedangSimpan = false;
					this.sedangEdit = false;
					this.getDataIbu();
					memuat.hide();
				}, 250);
			} else {
				this.$swal({
					title: "Perhatian",
					text: "Anda belum melakukan perubahan!",
					icon: "warning",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		},
	},
};
</script>

<style></style>
