<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
    <h1 class="h2 text-primary-emphasis">Wali Kelas {{ rombelWali.nama }}</h1>
  </div>
  <div class="my-3">
    <div class="card shadow my-3 vl-parent" ref="progressCardWalas">
      <div class="card-header d-flex row gap-1">
        <span class="col-auto">
          Progres kelengkapan data {{ rombelWali.nama }} :
          <strong :style="'color:' + warna">{{ persen_total }}%</strong>
        </span>
        <span class="col-auto ms-auto">
          <strong>{{ jumlahSiswa }}</strong>
          siswa aktif
        </span>
      </div>
      <div v-if="progressData.length"
        class="card-body row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 g-3 gy-4 text-center justify-content-around">
        <div v-for="d in progressData" :key="d">
          <CircularProgress class="col-4 fs-4 mb-2" :progress="d.persen_data_siswa" />
          <h6 class="mb-1">{{ d.nama }}</h6>
          <p class="text-center mb-1 small text-muted">{{ d.nis }} ~ {{ d.singkatan }}</p>
          <router-link :to="'/data-siswa-walas/' + d.siswa_id + '/detail'" class="btn btn-sm btn-outline-secondary">
            Lihat detail &raquo;
          </router-link>
        </div>
      </div>
      <div v-else-if="sedangMemuat" class="card-body d-flex align-items-center justify-content-center gap-2">
        <div class="spinner-border border-3 text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="text-secondary d-flex align-items-baseline gap-1">
          <span>Memuat</span>
          <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="card-body text-center">Data tidak ditemukan.</div>
    </div>
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress.vue';

export default {
  name: 'DasborKontrol',
  data() {
    return {
      persen_total: 0,
      progressData: [],
      sedangMemuat: false,
      rombelWali: {},
      jumlahSiswa: null,
    }
  },
  components: {
    CircularProgress
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    token() {
      return this.$store.getters.getToken;
    },
    warna() {
      if (this.persen_total == 0) {
        return '#000';
      } else if (this.persen_total < 33) {
        return 'red';
      } else if (this.persen_total < 66) {
        return 'orange';
      } else if (this.persen_total < 100) {
        return '#5394fd';
      } else {
        return 'green'
      }
    }
  },
  watch: {
  },
  mounted() {
    this.getRombelWali();
  },
  beforeUnmount() { },
  methods: {
    async getRombelWali() {
      try {
        const response = await this.axios.post('/kurikulum/rombel/walas', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.rombelWali = response.data.rombelWali;
          this.getPersentaseProgres();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getPersentaseProgres() {
      this.sedangMemuat = true;
      try {
        const response = await this.axios.post('/data-siswa/persentase-rombel', {
          rombel_id: this.rombelWali.rombel_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          setTimeout(() => {
            this.progressData = response.data.progressDataRombel;
            let jumlah = 0;
            let persen = 0;
            for (const p of this.progressData) {
              if (p.status_siswa_id == 1) {
                persen += p.persen_data_siswa;
                jumlah++;
              }
              p.persen_data_siswa = Math.round((p.persen_data_siswa + Number.EPSILON) * 100) / 100;
            }
            this.jumlahSiswa = jumlah;
            this.persen_total = Math.round((persen / jumlah + Number.EPSILON) * 100) / 100;
            let memuat = this.$loading.show({
              container: this.$refs.progressCardWalas,
              loader: 'spinner'
            })
            setTimeout(() => {
              this.sedangMemuat = false;
              memuat.hide();
            }, 250)
          }, 500);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
  }
}
</script>

<style></style>