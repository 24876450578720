<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
    <h1 class="h2 text-primary-emphasis">Data Siswa</h1>
  </div>
  <div class="my-3">
    <div class="card shadow">
      <div class="card-header">
        <div class="d-flex row gap-1">
          <span class="col-auto my-auto h5">
            Progres kelengkapan data per siswa
          </span>
          <div class="col-auto ms-auto">
            <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Cari . . ."
              v-model="key" @keyup="search" @search="search">
          </div>
        </div>
      </div>
      <div class="card-body vl-parent" ref="tableDataSiswa">
        <div v-if="dataSiswa.length" class="table-responsive shadow">
          <table class="table table-sm mb-0 small">
            <thead class="table-secondary align-middle">
              <tr>
                <th>#</th>
                <th class="position-sticky start-0">NIS</th>
                <th>Nama</th>
                <th>Rombel</th>
                <th>Status</th>
                <th>Progres Data</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="s, index in dataSiswa" :key="s">
                <td>{{ index+=1 }}</td>
                <td class="position-sticky start-0">{{ s.nis }}</td>
                <td>{{ s.nama }}</td>
                <td>{{ s.nama_rombel }}</td>
                <td>{{ s.singkatan }}</td>
                <td>
                  <div class="hstack gap-1">
                    <strong :style="'color:' + s.warna">{{ s.persen_data_siswa }}%</strong>
                    <router-link :to="$route.path + '/' + s.siswa_id + '/detail'"
                      class="d-inline-flex align-items-center ms-auto" v-tooltip="'Lihat detail'">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                        class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                        <path fill-rule="evenodd"
                          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                      </svg></router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="sedangMemuat" class="d-flex align-items-center justify-content-center gap-2">
          <div class="spinner-border border-3 text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="text-secondary d-flex align-items-baseline gap-1">
            <span>Memuat</span>
            <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else class="text-center">Data tidak ditemukan.</div>
        <span v-if="count" class="small text-muted fst-italic">
          Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataSiswa.length }} dari total {{ count }} data
        </span>
      </div>
      <div class="card-footer" v-if="count">
        <div class="row row-cols-auto justify-content-sm-between justify-content-center">
          <div class="row row-cols-auto">
            <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
            <div class="col-auto ps-1">
              <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
                <option v-for="l in arrayLimit" :key="l" :value="l">
                  {{ l }} baris
                </option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <ul class="pagination pagination-sm shadow mb-0">
              <li class="page-item">
                <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                  @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
              </li>
              <li class="page-item" v-for="p in  pageCount " :key="p">
                <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
                <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                  @click="pageCurrent = p">{{ p }}</button>
                <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
                (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                  @click="pageCurrent = p">{{ p }}</button>
                <button
                  v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                  class="page-link" @click="pageCurrent = p">{{ p }}</button>
                <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
                (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
                (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                  @click="pageCurrent = p">...</button>
              </li>
              <li class="page-item">
                <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                  @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
export default {
  name: 'SemuaSiswaView',
  data() {
    return {
      dataSiswa: [],
      sedangMemuat: false,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 25,
      pageCount: 0,
      key: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataSiswaAll(1);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataSiswaAll(1);
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  mounted() {
    this.getDataSiswaAll();
  },
  beforeUnmount() { },
  methods: {
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataSiswaAll(1);
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    async getDataSiswaAll(n) {
      let memuat0;
      let memuat1;
      if (n) {
        memuat1 = this.$loading.show({
          container: this.$refs.tableDataSiswa,
          loader: 'spinner'
        })
      } else {
        this.sedangMemuat = true;
      }
      try {
        const response = await this.axios.post('/data-siswa/persentase-siswa-all', {
          limit: this.pageLimit,
          offset: this.pageOffset,
          key: this.key,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.rombel = response.data.rombel;
          setTimeout(() => {
            this.dataSiswa = response.data.progressDataSiswa;
            this.count = response.data.count;
            this.pageCount = Math.ceil(this.count / this.pageLimit);
            for (const p of this.dataSiswa) {
              if (p.persen_data_siswa == 0) {
                p.warna = '#000';
              } else if (p.persen_data_siswa < 33) {
                p.warna = 'red';
              } else if (p.persen_data_siswa < 66) {
                p.warna = 'orange';
              } else if (p.persen_data_siswa < 100) {
                p.warna = '#5394fd';
              } else {
                p.warna = 'green'
              }
              p.persen_data_siswa = Math.round((p.persen_data_siswa + Number.EPSILON) * 100) / 100;
            }
            if (!n) {
              memuat0 = this.$loading.show({
                container: this.$refs.tableDataSiswa,
                loader: 'spinner'
              })
              setTimeout(() => {
                this.sedangMemuat = false;
                memuat0.hide();
              }, 250)
            }
          }, 500);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n) {
        setTimeout(() => {
          memuat1.hide();
        }, 250);
      }
    },
  }
}
</script>

<style></style>