<template>
	<div>
		<div v-if="user.role === 'siswa'" class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
			<h1 class="h2 text-primary-emphasis">Data Rinci Siswa</h1>
		</div>
		<div :class="{ 'card shadow my-3': user.role === 'siswa' }">
			<form @submit.prevent="updateData">
				<p v-if="user.role === 'siswa'" class="card-header">
					Progres kelengkapan data rinci siswa :
					<strong :style="'color:' + warna">{{ persentase }}%</strong>
				</p>
				<div class="vl-parent" :class="{ 'card-body': user.role === 'siswa' }" ref="detailSiswaCard">
					<div v-if="detailSiswa">
						<div v-if="user.role !== 'siswa'">
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<strong>Progres kelengkapan data rinci siswa</strong>:
								</label>
								<div class="col ps-sm-1">
									<span class="form-control-plaintext py-1 fw-bold" :style="'color:' + warna"> {{ persentase }}%</span>
								</div>
							</div>
							<hr class="my-1" />
						</div>
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Nomor Induk Siswa</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1"> {{ detailSiswa.nis }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Nomor Pembayaran</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1"> {{ detailSiswa.va }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Alamat Email</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1"> {{ detailSiswa.username }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Status</span>:</label
							>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1"> {{ detailSiswa.ket_status }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Rombongan Belajar</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1">{{ detailSiswa.nama_rombel }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Prgram Keahlian</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1">{{ detailSiswa.progli }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Konsentrasi Keahlian</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1">{{ detailSiswa.konseli }}</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
								<span>Nama Lengkap</span>:
							</label>
							<div class="col ps-sm-1">
								<span class="form-control-plaintext py-1" :class="{ 'fst-italic text-danger-emphasis': !detailSiswa.nama }">
									{{ detailSiswa.nama || "belum terisi" }}
								</span>
							</div>
						</div>
						<hr class="my-1" />
						<div class="row">
							<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"><span>Foto</span>:</label>
							<div class="col-auto ps-sm-1 pe-1 position-relative">
								<img v-if="detailSiswa.foto" :src="detailSiswa.foto" alt="Foto" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
								<img v-else-if="previewFoto" :src="previewFoto" alt="Foto" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
								<div v-else class="form-control-plaintext hstack gap-2 py-1">
									<span class="fst-italic text-danger-emphasis">belum terisi</span>
									<button
										v-if="user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa'"
										type="button"
										class="btn btn-sm btn-outline-secondary px-1 py-0 d-inline-flex"
										data-bs-toggle="modal"
										data-bs-target="#editFoto"
										v-tooltip="'Unggah Foto'">
										<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
											<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
											<path
												d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
										</svg>
									</button>
								</div>
								<div
									v-if="(detailSiswa.foto || previewFoto) && (user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa')"
									class="position-absolute top-0 end-0 m-2 p-1 hstack gap-2 bg-dark bg-opacity-75 border rounded shadow">
									<button
										type="button"
										class="btn btn-link btn-sm link-light d-inline-flex p-0"
										data-bs-toggle="modal"
										data-bs-target="#lihatFoto"
										v-tooltip="'Lihat Foto'"
										@click="previewFoto ? (lihatFoto = previewFoto) : (lihatFoto = detailSiswa.foto)">
										<svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
											<path
												fill-rule="evenodd"
												d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
										</svg>
									</button>
									<div class="vr text-light"></div>
									<button type="button" class="btn btn-sm btn-link link-light d-inline-flex p-0" data-bs-toggle="modal" data-bs-target="#editFoto" v-tooltip="'Ganti Foto'">
										<svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
											<path
												d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
											<path
												fill-rule="evenodd"
												d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
										</svg>
									</button>
								</div>
							</div>
						</div>
						<hr class="my-1" />
						<div>
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Kontak</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.kontak || 'Nomor Telepon (WhatsApp)'"
										v-model="kontak"
										pattern="[0-9]{10,13}"
										title="Nomor telepon terdiri dari angka sebanyak 10 hingga 13 digit." />
									<span class="form-text fst-italic">Masukkan nomor telepon yang dapat dihubungi melalui aplikasi WhatsApp maupun seluler.</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span class="form-control-plaintext py-1" :class="{ 'fst-italic text-danger-emphasis': !detailSiswa.kontak }">
										{{ detailSiswa.kontak || "belum terisi" }}
									</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Agama</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1 py-1">
									<div v-for="a in listAgama" :key="a" class="form-check form-check-inline">
										<input class="form-check-input" :id="'ag' + a.agama_id" type="radio" :value="a.agama_id" v-model="agama_id" :checked="a.agama_id == detailSiswa.agama_id" />
										<label class="form-check-label" :for="'ag' + a.agama_id">{{ a.nama }}</label>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.nama_agama,
										}">
										{{ detailSiswa.nama_agama || "belum terisi" }}
									</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Jenis Kelamin</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1 py-1">
									<div v-for="jk in listJk" :key="jk" class="form-check form-check-inline">
										<input
											class="form-check-input"
											:id="'jk' + jk.singkatan"
											type="radio"
											:value="jk.singkatan"
											v-model="jen_kel"
											:checked="jk.singkatan == detailSiswa.jenis_kelamin" />
										<label class="form-check-label" :for="'jk' + jk.singkatan">{{ jk.keterangan }}</label>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.jen_kel,
										}">
										{{ detailSiswa.jen_kel || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Tinggi Badan</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.tinggi_badan || 'Tinggi badan (cm)'" v-model="tinggi_badan" />
									<span class="form-text fst-italic">Masukkan tinggi badan dalam satuan sentimeter (cm).</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.tinggi_badan,
										}">
										{{ detailSiswa.tinggi_badan || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Berat Badan</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.berat_badan || 'Berat badan (kg)'" v-model="berat_badan" />
									<span class="form-text fst-italic">Masukkan berat badan dalam satuan kilogram (kg).</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.berat_badan,
										}">
										{{ detailSiswa.berat_badan || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Lingkar Kepala</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.lingkar_kepala || 'Lingkar kepala (cm)'" v-model="lingkar_kepala" />
									<span class="form-text fst-italic">Masukkan lingkar (keliling) kepala dalam satuan sentimeter (cm).</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.lingkar_kepala,
										}">
										{{ detailSiswa.lingkar_kepala || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Berkebutuhan Khusus</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="khusus"
										:placeholder="detailSiswa.jenis_kebutuhan_khusus || 'Tidak ada'"
										label="nama"
										:options="listKebutuhanKhusus"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Jenis kebutuhan khusus tidak ditemukan.</template>
									</v-select>
									<span class="form-text fst-italic">Abaikan atau silakan pilih "Tidak ada" jika tidak memiliki kebutuhan khusus.</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span class="form-control-plaintext py-1">{{ detailSiswa.jenis_kebutuhan_khusus || "Tidak ada" }}</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"><span>Hobi</span>:</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="hobi"
										:placeholder="detailSiswa.ket_hobi || 'Pilih salah satu'"
										label="keterangan"
										:options="listHobi"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Hobi tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.ket_hobi,
										}">
										{{ detailSiswa.ket_hobi || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Cita-cita</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="cita_cita"
										:placeholder="detailSiswa.ket_cita_cita || 'Pilih salah satu'"
										label="keterangan"
										:options="listCitaCita"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Cita-cita tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.ket_cita_cita,
										}">
										{{ detailSiswa.ket_cita_cita || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nomor Induk Kependudukan</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.nik || 'Nomor Induk Kependudukan'"
										v-model="nik"
										pattern="[0-9]{16}"
										title="Nomor Induk Kependudukan terdiri dari angka sebanyak 16 digit." />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.nik,
										}">
										{{ detailSiswa.nik || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nomor Kartu Keluarga</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.no_kk || 'Nomor Kartu Keluarga'"
										v-model="no_kk"
										pattern="[0-9]{16}"
										title="Nomor Kartu Keluarga terdiri dari angka sebanyak 16 digit." />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.no_kk,
										}">
										{{ detailSiswa.no_kk || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Foto Kartu Keluarga</span>:
								</label>
								<div class="col-auto ps-sm-1 pe-1 position-relative">
									<img v-if="previewKk" :src="previewKk" alt="Foto" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
									<img
										v-else-if="detailSiswa.foto_kk"
										:src="detailSiswa.foto_kk"
										alt="Foto"
										style="max-width: 100%; max-height: 140px"
										class="my-1 border border-secondary shadow" />
									<div v-else class="form-control-plaintext hstack gap-2 py-1">
										<span class="fst-italic text-danger-emphasis">belum terisi</span>
										<button
											v-if="user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa'"
											type="button"
											class="btn btn-sm btn-outline-secondary px-1 py-0 d-inline-flex"
											data-bs-toggle="modal"
											data-bs-target="#editKk"
											v-tooltip="'Unggah Foto KK'">
											<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
												<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
												<path
													d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
											</svg>
										</button>
									</div>
									<div
										v-if="(detailSiswa.foto_kk || previewKk) && (user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa')"
										class="position-absolute top-0 end-0 m-2 p-1 hstack gap-2 bg-dark bg-opacity-75 border rounded shadow">
										<button
											type="button"
											class="btn btn-link link-light d-inline-flex p-0"
											data-bs-toggle="modal"
											data-bs-target="#lihatFoto"
											v-tooltip="'Lihat Foto'"
											@click="previewKk ? (lihatFoto = previewKk) : (lihatFoto = detailSiswa.foto_kk)">
											<svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
												<path
													fill-rule="evenodd"
													d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
											</svg>
										</button>
										<div class="vr text-light"></div>
										<button type="button" class="btn btn-link link-light d-inline-flex p-0" data-bs-toggle="modal" data-bs-target="#editKk" v-tooltip="'Ganti Foto KK'">
											<svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
												<path
													d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
												<path
													fill-rule="evenodd"
													d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
											</svg>
										</button>
									</div>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nomor Registrasi Akta Kelahiran</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.no_reg_akta_lahir || 'Nomor Registrasi Akta Kelahiram'"
										v-model="no_reg_akta_lahir"
										title="Sesuaikan dengan nomor yang tertera pada akta kelahiran." />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.no_reg_akta_lahir,
										}">
										{{ detailSiswa.no_reg_akta_lahir || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Tempat Lahir</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="tempat_lahir"
										:placeholder="detailSiswa.nama_tempat_lahir || 'Pilih kab/kota tempat lahir'"
										label="nama"
										:options="listTempatLahir"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Tempat lahir tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.nama_tempat_lahir,
										}">
										{{ detailSiswa.nama_tempat_lahir || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Tanggal Lahir</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.tanggal_lahir ? new Date(detailSiswa.tanggal_lahir).toLocaleDateString('id-ID') : 'Tanggal lahir'"
										v-model="tanggal_lahir"
										onfocus="(this.type='date')"
										onblur="(this.type='text')" />
								</div>
								<div v-else class="col ps-sm-1">
									<span v-if="detailSiswa.tanggal_lahir" class="form-control-plaintext py-1"> {{ new Date(detailSiswa.tanggal_lahir).toLocaleDateString("id-ID") }}</span>
									<span v-else class="form-control-plaintext fst-italic text-danger-emphasis">belum terisi</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Penulisan Tempat & Tanggal Lahir</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="text" class="form-control form-control-sm shadow" :placeholder="detailSiswa.penulisan_ttl || 'Penulisan tanggal lahir'" v-model="penulisan_ttl" />
									<span class="form-text fst-italic">
										Isi sesuai dengan penulisan tempat dan tanggal lahir pada ijazah SD/MI maupun SMP/MTs. Contoh :
										<strong>Jogjakarta, 29 Pebruari 2004</strong>.
									</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.penulisan_ttl,
										}">
										{{ detailSiswa.penulisan_ttl || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Foto Akta Kelahiran</span>:
								</label>
								<div class="col-auto ps-sm-1 pe-1 position-relative">
									<img
										v-if="detailSiswa.foto_akta"
										:src="detailSiswa.foto_akta"
										alt="Foto"
										style="max-width: 100%; max-height: 140px"
										class="my-1 border border-secondary shadow" />
									<img v-else-if="previewAkta" :src="previewAkta" alt="Foto" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
									<div v-else class="form-control-plaintext hstack gap-2 py-1">
										<span class="fst-italic text-danger-emphasis">belum terisi</span>
										<button
											v-if="user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa'"
											type="button"
											class="btn btn-sm btn-outline-secondary px-1 py-0 d-inline-flex"
											data-bs-toggle="modal"
											data-bs-target="#editAkta"
											v-tooltip="'Unggah Foto Akta'">
											<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
												<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
												<path
													d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
											</svg>
										</button>
									</div>
									<div
										v-if="(detailSiswa.foto_akta || previewAkta) && (user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa')"
										class="position-absolute top-0 end-0 m-2 p-1 hstack gap-2 bg-dark bg-opacity-75 border rounded shadow">
										<button
											type="button"
											class="btn btn-link link-light d-inline-flex p-0"
											data-bs-toggle="modal"
											data-bs-target="#lihatFoto"
											v-tooltip="'Lihat Foto'"
											@click="previewAkta ? (lihatFoto = previewAkta) : (lihatFoto = detailSiswa.foto_akta)">
											<svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
												<path
													fill-rule="evenodd"
													d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
											</svg>
										</button>
										<div class="vr text-light"></div>
										<button type="button" class="btn btn-link link-light d-inline-flex p-0" data-bs-toggle="modal" data-bs-target="#editAkta" v-tooltip="'Ganti Foto Akta'">
											<svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
												<path
													d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
												<path
													fill-rule="evenodd"
													d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
											</svg>
										</button>
									</div>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Anak ke-</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.anak_ke || 'Anak ke-'" v-model="anak_ke" />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.anak_ke,
										}">
										{{ detailSiswa.anak_ke || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Jumlah saudara kandung</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.jumlah_saudara || 'Jumlah saudara kandung'" v-model="jumlah_saudara" />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.jumlah_saudara,
										}">
										{{ detailSiswa.jumlah_saudara || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nomor Induk Siswa Nasional</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.nisn || 'Nomor Induk Siswa Nasional'"
										v-model="nisn"
										pattern="[0-9]{10}"
										title="Nomor Induk Siswa Nasional terdiri dari angka sebanyak 10 digit." />
									<span class="form-text fst-italic">
										Masukkan NISN sesuai dengan yang tertera pada Ijazah SMP/MTs atau dapat dicari melalui
										<a href="https://nisn.data.kemdikbud.go.id/index.php/Cindex/formcaribynama" target="_blank" rel="noopener noreferrer" class="fst-normal">Situs Web NISN</a>.
									</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.nisn,
										}">
										{{ detailSiswa.nisn || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Asal SMP/MTs</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="asal_sekolah"
										:filterable="false"
										:placeholder="detailSiswa.nama_asal_sekolah || 'Asal Sekolah'"
										:options="listAsalSekolah"
										label="nama"
										@search="onSearch"
										:selectOnTab="true"
										:clearable="false"
										:dropdown-should-open="cariAsalSekolah"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #option="option">
											<div class="text-wrap">
												{{ option.nama }} ~ <small class="fst-italic">{{ option.alamat }}</small>
											</div>
										</template>
										<template #no-options>Sekolah tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span v-if="detailSiswa.npsn_asal_sekolah" class="form-control-plaintext py-1"> {{ detailSiswa.nama_asal_sekolah }} ~ ({{ detailSiswa.npsn_asal_sekolah }})</span>
									<span v-else class="form-control-plaintext fst-italic text-danger-emphasis">belum terisi</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Nomor Seri Ijazah SMP/MTs</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.no_seri_ijazah_smp || 'Nomor Seri Ijazah SMP/MTs'"
										v-model="no_seri_ijazah_smp"
										title="Sesuaikan dengan nomor yang tertera pada Ijazah SMP/MTs." />
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.no_seri_ijazah_smp,
										}">
										{{ detailSiswa.no_seri_ijazah_smp || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Foto Ijazah SMP/MTs</span>:
								</label>
								<div class="col-auto ps-sm-1 pe-1 position-relative">
									<img
										v-if="detailSiswa.foto_ijazah_smp"
										:src="detailSiswa.foto_ijazah_smp"
										alt="Foto"
										style="max-width: 100%; max-height: 140px"
										class="my-1 border border-secondary shadow" />
									<img v-else-if="previewIjazah" :src="previewIjazah" alt="Foto" style="max-width: 100%; max-height: 140px" class="my-1 border border-secondary shadow" />
									<div v-else class="form-control-plaintext hstack gap-2 py-1">
										<span class="fst-italic text-danger-emphasis">belum terisi</span>
										<button
											v-if="user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa'"
											type="button"
											class="btn btn-sm btn-outline-secondary px-1 py-0 d-inline-flex"
											data-bs-toggle="modal"
											data-bs-target="#editIjazah"
											v-tooltip="'Unggah Foto Ijazah'">
											<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
												<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
												<path
													d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
											</svg>
										</button>
									</div>
									<div
										v-if="(detailSiswa.foto_ijazah_smp || previewIjazah) && (user.role === 'admin' || user.guru_id == detailSiswa.wali_kelas || user.role === 'siswa')"
										class="position-absolute top-0 end-0 m-2 p-1 hstack gap-2 bg-dark bg-opacity-75 border rounded shadow">
										<button
											type="button"
											class="btn btn-link link-light d-inline-flex p-0"
											data-bs-toggle="modal"
											data-bs-target="#lihatFoto"
											v-tooltip="'Lihat Foto'"
											@click="previewIjazah ? (lihatFoto = previewIjazah) : (lihatFoto = detailSiswa.foto_ijazah_smp)">
											<svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
												<path
													fill-rule="evenodd"
													d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
											</svg>
										</button>
										<div class="vr text-light"></div>
										<button type="button" class="btn btn-link link-light d-inline-flex p-0" data-bs-toggle="modal" data-bs-target="#editIjazah" v-tooltip="'Ganti Foto Akta'">
											<svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
												<path
													d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
												<path
													fill-rule="evenodd"
													d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
											</svg>
										</button>
									</div>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Pendidikan Dasar</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1 py-1">
									<div v-for="pd in listPd" :key="pd" class="form-check form-check-inline">
										<input
											class="form-check-input"
											:id="'pd' + pd.singkatan"
											type="radio"
											:value="pd.singkatan"
											v-model="pen_das"
											:checked="pd.singkatan == detailSiswa.pendidikan_dasar" />
										<label class="form-check-label" :for="'pd' + pd.singkatan">{{ pd.keterangan }}</label>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.pen_das,
										}">
										{{ detailSiswa.pen_das || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Pernah PAUD Formal</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1 py-1">
									<div class="form-check form-check-inline">
										<input type="radio" class="form-check-input" id="pf1" v-model="pernah_paud_formal" value="1" :checked="detailSiswa.pernah_paud_formal == 1" />
										<label class="form-check-label" for="pf1">Ya</label>
									</div>
									<div class="form-check form-check-inline">
										<input type="radio" class="form-check-input" id="pf0" v-model="pernah_paud_formal" value="0" :checked="detailSiswa.pernah_paud_formal == 0" />
										<label class="form-check-label" for="pf0">Tidak</label>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span v-if="typeof detailSiswa.pernah_paud_formal == 'number'" class="form-control-plaintext py-1"> {{ detailSiswa.pernah_paud_formal ? "Ya" : "Tidak" }}</span>
									<span v-else class="form-control-plaintext fst-italic text-danger-emphasis">belum terisi</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Pernah PAUD Nonformal</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1 py-1">
									<div class="form-check form-check-inline">
										<input type="radio" class="form-check-input" id="pnf1" v-model="pernah_paud_nonformal" value="1" :checked="detailSiswa.pernah_paud_nonformal == 1" />
										<label class="form-check-label" for="pnf1">Ya</label>
									</div>
									<div class="form-check form-check-inline">
										<input type="radio" class="form-check-input" id="pnf0" v-model="pernah_paud_nonformal" value="0" :checked="detailSiswa.pernah_paud_nonformal == 0" />
										<label class="form-check-label" for="pnf0">Tidak</label>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span v-if="typeof detailSiswa.pernah_paud_nonformal == 'number'" class="form-control-plaintext py-1">
										{{ detailSiswa.pernah_paud_nonformal ? "Ya" : "Tidak" }}</span
									>
									<span v-else class="form-control-plaintext fst-italic text-danger-emphasis">belum terisi</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1"
									><span>Alamat</span>:</label
								>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.alamat || 'Nama jalan, nomor, gang, dusun, dsb. (Alamat pelengkap)'"
										v-model="alamat" />
									<div class="row gx-1 gy-2 pt-1 small">
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RT :</label>
											<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.rt || 'RT'" v-model="rt" />
										</div>
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RW :</label>
											<input type="number" class="form-control form-control-sm shadow" :placeholder="detailSiswa.rw || 'RW'" v-model="rw" />
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Provinsi :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="prov"
												:placeholder="detailSiswa.nama_prov || 'Pilih provinsi'"
												label="nama"
												:options="listProv"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small class="px-1">Provinsi tidak ditemukan.</small>
												</template>
											</v-select>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kabupaten/Kota :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="kab_kota"
												:placeholder="detailSiswa.nama_kab_kota || 'Pilih kabupaten/kota'"
												label="nama"
												:options="listKabKota"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" :required="kab_kotaRequired && !kab_kota" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small v-if="prov" class="px-1">Kabupaten/Kota tidak ditemukan.</small>
													<small v-else class="px-1">Silakan pilih provinsi terebih dahulu.</small>
												</template>
											</v-select>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kecamatan :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="kec"
												:placeholder="detailSiswa.nama_kec || 'Pilih kecamatan'"
												label="nama"
												:options="listKec"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" :required="kecRequired && !kec" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small v-if="kab_kota" class="px-1">Kecamatan tidak ditemukan.</small>
													<small v-else class="px-1">Silakan pilih kabupaten/kota terebih dahulu.</small>
												</template>
											</v-select>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Desa/Kelurahan :</label>
											<v-select
												class="bg-white rounded shadow"
												v-model="desa"
												:placeholder="detailSiswa.nama_desa || 'Pilih desa/kelurahan'"
												label="nama"
												:options="listDesa"
												:selectOnTab="true"
												:clearable="true"
												append-to-body
												:calculate-position="withPopper">
												<template #search="{ attributes, events }">
													<input class="vs__search" :required="desaRequired && !desa" v-bind="attributes" v-on="events" />
												</template>
												<template #no-options>
													<small v-if="kec" class="px-1">Desa/Kelurahan tidak ditemukan.</small>
													<small v-else class="px-1">Silakan pilih kecamatan terebih dahulu.</small>
												</template>
											</v-select>
										</div>
									</div>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.alamat,
										}">
										{{ detailSiswa.alamat || "belum terisi" }}
									</span>
									<div class="row g-1 small">
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RT :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !detailSiswa.rt,
												}">
												{{ detailSiswa.rt || "belum terisi" }}</span
											>
										</div>
										<div class="col-4 col-lg-3 col-xl-2">
											<label class="form-label text-primary-emphasis mb-0">RW :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !detailSiswa.rw,
												}">
												{{ detailSiswa.rw || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Desa/Kelurahan :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !detailSiswa.nama_desa,
												}">
												{{ detailSiswa.nama_desa || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kecamatan :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !detailSiswa.nama_kec,
												}">
												{{ detailSiswa.nama_kec || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Kabupaten/Kota :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !detailSiswa.nama_kab_kota,
												}">
												{{ detailSiswa.nama_kab_kota || "belum terisi" }}</span
											>
										</div>
										<div class="col-sm-6 col-md-12 col-lg-6 col-xl-4">
											<label class="form-label text-primary-emphasis mb-0">Provinsi :</label>
											<span
												class="form-control form-control-sm"
												:class="{
													'fst-italic text-danger-emphasis': !detailSiswa.nama_prov,
												}">
												{{ detailSiswa.nama_prov || "belum terisi" }}</span
											>
										</div>
									</div>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Tautan Google Maps</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="text"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.gmaps || 'Tautan google maps'"
										v-model="gmaps"
										title="Pilih lokasi rumah melalui google maps > bagikan > salin tautan (link)." />
									<span class="form-text fst-italic">
										Masukkan/tempel tautan dari google maps yang bisa didapatkan dengan cara: Buka aplikasi
										<a href="https://maps.google.com" target="_blank" rel="noopener noreferrer" class="fst-normal">Google Maps</a>
										&raquo; pilih lokasi rumah &raquo; bagikan &raquo; salin.
									</span>
								</div>
								<div v-else class="col ps-sm-1">
									<span v-if="detailSiswa.gmaps" class="form-control-plaintext py-1">
										<a :href="detailSiswa.gmaps" target="_blank" rel="noopener noreferrer">{{ detailSiswa.gmaps }}</a>
									</span>
									<span v-else class="form-control-plaintext py-1 fst-italic text-danger-emphasis"> belum terisi</span>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Tempat Tinggal</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="tinggal"
										:placeholder="detailSiswa.ket_tempat_tinggal || 'Pilih salah satu'"
										label="keterangan"
										:options="listTempatTinggal"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Jenis tempat tinggal tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.ket_tempat_tinggal,
										}">
										{{ detailSiswa.ket_tempat_tinggal || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Moda Transportasi</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<v-select
										class="bg-white rounded shadow"
										v-model="mod_trans"
										:placeholder="detailSiswa.ket_moda_trasportasi || 'Pilih salah satu'"
										label="keterangan"
										:options="listModTrans"
										:selectOnTab="true"
										:clearable="true"
										append-to-body
										:calculate-position="withPopper">
										<template #search="{ attributes, events }">
											<input class="vs__search" v-bind="attributes" v-on="events" />
										</template>
										<template #no-options>Moda transportasi tidak ditemukan.</template>
									</v-select>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.ket_moda_transportasi,
										}">
										{{ detailSiswa.ket_moda_transportasi || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Jarak Rumah</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="number"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.jarak_rumah || 'Jarak dari tempat tinggal ke sekolah (km)'"
										v-model="jarak_rumah" />
									<span class="form-text fst-italic"> Masukkan jarak dari tempat tinggal ke sekolah dalam satuan kilometer (km). </span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.jarak_rumah,
										}">
										{{ detailSiswa.jarak_rumah || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Waktu Tempuh</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<input
										type="number"
										class="form-control form-control-sm shadow"
										:placeholder="detailSiswa.waktu_tempuh || 'Waktu tempuh dari tempat tinggal ke sekolah (menit)'"
										v-model="waktu_tempuh" />
									<span class="form-text fst-italic"> Masukkan waktu tempuh dari tempat tinggal ke sekolah dalam satuan menit. </span>
								</div>
								<div v-else class="col ps-sm-1">
									<span
										class="form-control-plaintext py-1"
										:class="{
											'fst-italic text-danger-emphasis': !detailSiswa.waktu_tempuh,
										}">
										{{ detailSiswa.waktu_tempuh || "belum terisi" }}</span
									>
								</div>
							</div>
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<span>Catatan</span>:
								</label>
								<div v-if="sedangEdit" class="col ps-sm-1">
									<textarea class="form-control form-control-sm shadow" v-model="catatan" rows="4" :placeholder="detailSiswa.catatan || 'Catatan'"></textarea>
								</div>
								<div v-else class="col ps-sm-1">
									<span class="form-control-plaintext py-1"> {{ detailSiswa.catatan || "-" }}</span>
								</div>
							</div>
						</div>
						<div v-if="user.role !== 'siswa'">
							<hr class="my-1" />
							<div class="row">
								<label class="col-sm-4 col-md-5 col-lg-4 col-xl-3 col-form-label d-flex justify-content-sm-between pe-0 gap-1 text-primary-emphasis py-1">
									<i>Terakhir diperbarui</i>:
								</label>
								<div class="col ps-sm-1 fst-italic">
									<div class="form-control-plaintext py-1">
										{{
											new Date(detailSiswa.terakhir_diperbarui).toLocaleString("id-ID", {
												dateStyle: "long",
												timeStyle: "long",
											})
										}}
									</div>
								</div>
							</div>
						</div>
						<div v-if="user.role === 'admin' || user.role === 'siswa' || user.guru_id == detailSiswa.wali_kelas" class="d-flex position-fixed fixed-bottom justify-content-end">
							<div class="position-absolute bottom-0 end-0 p-4">
								<div v-if="sedangEdit" class="btn-group btn-group-sm bg-body-tertiary bg-opacity-75 rounded shadow m-1">
									<button type="button" class="btn btn-outline-secondary px-4 shadow" @click="sedangEdit = false">Batal</button>
									<button type="submit" class="btn btn-outline-success px-4 shadow" :disabled="sedangSimpan">
										<span v-if="sedangSimpan" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
										<span v-if="sedangSimpan" role="status">Menyimpan...</span>
										<span v-else>Simpan</span>
									</button>
								</div>
								<div v-else class="bg-body-tertiary bg-opacity-75 rounded shadow m-1">
									<button type="button" class="btn btn-sm btn-outline-primary px-4 shadow" @click="sedangEdit = true">Edit Data</button>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="sedangMemuat" class="d-flex align-items-center justify-content-center gap-2">
						<div class="spinner-border border-3 text-secondary" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						<div class="text-secondary d-flex align-items-baseline gap-1">
							<span>Memuat</span>
							<div class="spinner-grow text-secondary" style="width: 0.15rem; height: 0.15rem" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<div class="spinner-grow text-secondary" style="width: 0.15rem; height: 0.15rem" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<div class="spinner-grow text-secondary" style="width: 0.15rem; height: 0.15rem" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
					<div v-else class="text-center">Data tidak ditemukan.</div>
				</div>
				<div v-if="detailSiswa && user.role === 'siswa'" class="card-footer fst-italic">
					Terakhir diperbarui :
					{{
						new Date(detailSiswa.terakhir_diperbarui).toLocaleString("id-ID", {
							dateStyle: "long",
							timeStyle: "long",
						})
					}}
				</div>
			</form>
		</div>
		<div class="modal fade" id="editFoto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<form @submit.prevent="unggahFoto">
						<div class="modal-header">
							<legend class="modal-title">Unggah Foto</legend>
							<button type="button" id="tutupFormFoto" class="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div class="modal-body">
							<div class="input-group input-group-sm shadow">
								<input type="file" ref="fotoInput" class="form-control form-control-sm" accept="image/*" @change="handleInputFoto" required :disabled="sedangInput" />
								<span class="input-group-text" v-if="sedangInput">
									<span class="spinner-border spinner-border-sm text-secondary"></span>
								</span>
							</div>
							<img v-if="previewFotoInput" :src="previewFotoInput" style="max-width: 100%; max-height: 140px" class="mt-1 shadow" alt="Preview" />
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-success" :disabled="sedangInput || sedangUnggah">
								<span v-if="sedangUnggah" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
								<span v-if="sedangUnggah" role="status">Menyimpan...</span>
								<span v-else>Simpan</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal fade" id="editKk" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<form @submit.prevent="unggahKk">
						<div class="modal-header">
							<legend class="modal-title">Unggah Foto Kartu Keluarga</legend>
							<button type="button" id="tutupFormKk" class="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div class="modal-body">
							<div class="input-group input-group-sm shadow">
								<input type="file" ref="kkInput" class="form-control form-control-sm" accept="image/*" @change="handleInputKk" required :disabled="sedangInput" />
								<span class="input-group-text" v-if="sedangInput">
									<span class="spinner-border spinner-border-sm text-secondary"></span>
								</span>
							</div>
							<img v-if="previewKkInput" :src="previewKkInput" style="max-width: 100%; max-height: 140px" class="mt-1 shadow" alt="Preview" />
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-success" :disabled="sedangInput || sedangUnggah">
								<span v-if="sedangUnggah" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
								<span v-if="sedangUnggah" role="status">Menyimpan...</span>
								<span v-else>Simpan</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal fade" id="editAkta" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<form @submit.prevent="unggahAkta">
						<div class="modal-header">
							<legend class="modal-title">Unggah Foto Akta Kelahiran</legend>
							<button type="button" id="tutupFormAkta" class="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div class="modal-body">
							<div class="input-group input-group-sm shadow">
								<input type="file" ref="aktaInput" class="form-control form-control-sm" accept="image/*" @change="handleInputAkta" required :disabled="sedangInput" />
								<span class="input-group-text" v-if="sedangInput">
									<span class="spinner-border spinner-border-sm text-secondary"></span>
								</span>
							</div>
							<img v-if="previewAktaInput" :src="previewAktaInput" style="max-width: 100%; max-height: 140px" class="mt-1 shadow" alt="Preview" />
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-success" :disabled="sedangInput || sedangUnggah">
								<span v-if="sedangUnggah" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
								<span v-if="sedangUnggah" role="status">Menyimpan...</span>
								<span v-else>Simpan</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal fade" id="editIjazah" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<form @submit.prevent="unggahIjazah">
						<div class="modal-header">
							<legend class="modal-title">Unggah Foto Ijazah SMP/MTs</legend>
							<button type="button" id="tutupFormIjazah" class="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div class="modal-body">
							<div class="input-group input-group-sm shadow">
								<input type="file" ref="ijazahInput" class="form-control form-control-sm" accept="image/*" @change="handleInputIjazah" required :disabled="sedangInput" />
								<span class="input-group-text" v-if="sedangInput">
									<span class="spinner-border spinner-border-sm text-secondary"></span>
								</span>
							</div>
							<img v-if="previewIjazahInput" :src="previewIjazahInput" style="max-width: 100%; max-height: 140px" class="mt-1 shadow" alt="Preview" />
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-success" :disabled="sedangInput || sedangUnggah">
								<span v-if="sedangUnggah" class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
								<span v-if="sedangUnggah" role="status">Menyimpan...</span>
								<span v-else>Simpan</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal fade" id="lihatFoto" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<legend class="modal-title">Lihat Foto Layar Penuh</legend>
						<button type="button" id="tutupLihatFoto" class="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div class="modal-body">
						<img v-if="lihatFoto" :src="lihatFoto" class="w-100" alt="Preview" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { debounce } from "lodash";
import imageCompression from "browser-image-compression";
import { createPopper } from "@popperjs/core";
export default {
	name: "DetailSiswa",
	data() {
		return {
			sedangMemuat: false,
			sedangEdit: false,
			sedangInput: false,
			sedangUnggah: false,
			sedangSimpan: false,
			listAgama: [],
			listJk: [
				{
					singkatan: "L",
					keterangan: "Laki-laki",
				},
				{
					singkatan: "P",
					keterangan: "Perempuan",
				},
			],
			listKebutuhanKhusus: [],
			listHobi: [],
			listCitaCita: [],
			listTempatLahir: [],
			listAsalSekolah: [],
			listProv: [],
			listKabKota: [],
			listKec: [],
			listDesa: [],
			listKabKotaAwal: [],
			listKecAwal: [],
			listDesaAwal: [],
			kab_kotaRequired: false,
			kecRequired: false,
			desaRequired: false,
			listTempatTinggal: [],
			listModTrans: [],
			listPd: [
				{
					singkatan: "SD",
					keterangan: "Sekolah Dasar",
				},
				{
					singkatan: "MI",
					keterangan: "Madrasah Ibtida'iyyah",
				},
			],
			detailSiswa: null,
			persentase: 0,
			previewFotoInput: null,
			previewKkInput: null,
			previewAktaInput: null,
			previewIjazahInput: null,
			previewFoto: null,
			previewKk: null,
			previewAkta: null,
			previewIjazah: null,
			fileFoto: null,
			fileKk: null,
			fileAkta: null,
			fileIjazah: null,
			lihatFoto: null,
			kontak: null,
			agama_id: null,
			jen_kel: null,
			tinggi_badan: null,
			berat_badan: null,
			lingkar_kepala: null,
			khusus: null,
			hobi: null,
			cita_cita: null,
			nik: null,
			no_kk: null,
			no_reg_akta_lahir: null,
			tempat_lahir: null,
			tanggal_lahir: null,
			penulisan_ttl: null,
			anak_ke: null,
			jumlah_saudara: null,
			nisn: null,
			asal_sekolah: null,
			no_seri_ijazah_smp: null,
			pen_das: null,
			pernah_paud_formal: null,
			pernah_paud_nonformal: null,
			alamat: null,
			rt: null,
			rw: null,
			desa: null,
			kec: null,
			kab_kota: null,
			prov: null,
			gmaps: null,
			tinggal: null,
			mod_trans: null,
			jarak_rumah: null,
			waktu_tempuh: null,
			catatan: null,
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUserData;
		},
		token() {
			return this.$store.getters.getToken;
		},
		path() {
			return this.$route.path;
		},
		warna() {
			if (this.persentase == 0) {
				return "#000";
			} else if (this.persentase < 33) {
				return "red";
			} else if (this.persentase < 66) {
				return "orange";
			} else if (this.persentase < 100) {
				return "#5394fd";
			} else {
				return "green";
			}
		},
	},
	watch: {
		path() {
			this.sedangEdit = false;
			this.getDetailSiswa();
		},
		sedangEdit() {
			this.$route.meta.sedangEdit = this.sedangEdit;
			document.body.scroll(0, 0);
			document.documentElement.scroll(0, 0);
			if (this.sedangEdit) {
				window.addEventListener("beforeunload", this.handleBeforeUnload);
			} else {
				window.removeEventListener("beforeunload", this.handleBeforeUnload);
			}
		},
		prov() {
			this.kab_kota = null;
			this.kab_kotaRequired = Object(this.prov).wilayah_id !== Object(this.detailSiswa).prov;
			if (!this.prov) {
				this.listKabKota = this.listKabKotaAwal;
			} else {
				this.getListKabKota(this.prov.wilayah_id, 1);
			}
		},
		kab_kota() {
			this.kec = null;
			this.kecRequired = Object(this.prov).wilayah_id !== Object(this.detailSiswa).prov || Object(this.kab_kota).wilayah_id !== Object(this.detailSiswa).kab_kota;
			if (!this.kab_kota) {
				this.listKec = this.listKecAwal;
			} else {
				this.getListKec(this.kab_kota.wilayah_id, 1);
			}
		},
		kec() {
			this.desa = null;
			this.desaRequired =
				Object(this.prov).wilayah_id !== Object(this.detailSiswa).prov ||
				Object(this.kab_kota).wilayah_id !== Object(this.detailSiswa).kab_kota ||
				Object(this.kec).wilayah_id !== Object(this.detailSiswa).kec;
			if (!this.kec) {
				this.listDesa = this.listDesaAwal;
			} else {
				this.getListDesa(this.kec.wilayah_id, 1);
			}
		},
	},
	mounted() {
		this.getDetailSiswa();
		this.getListAgama();
		this.getListKebutuhanKhusus();
		this.getListHobi();
		this.getListCitaCita();
		this.getListTempatlahir();
		this.getListProv();
		this.getListTempatTinggal();
		this.getListModTrans();
	},
	beforeUnmount() {},
	methods: {
		handleBeforeUnload(event) {
			if (this.sedangEdit) {
				event.preventDefault();
				event.returnValue = "";
			}
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList, {
				placement: "bottom",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: [0, -1],
						},
					},
					{
						name: "toggleClass",
						enabled: true,
						phase: "write",
						fn({ state }) {
							component.$el.classList.toggle("drop-up", state.placement === "top");
						},
					},
				],
			});
			return () => popper.destroy();
		},
		onSearch(search, loading) {
			if (search.length) {
				loading(true);
				this.search(loading, search, this);
			}
		},
		search: debounce((loading, search, vm) => {
			vm.getListAsalSekolah(search).then(() => {
				loading(false);
			});
		}, 500),
		async getDetailSiswa() {
			this.detailSiswa = null;
			this.sedangMemuat = true;
			try {
				const response = await this.axios.post(
					"/data-siswa/detail",
					{
						siswa_id: this.$route.params.siswa_id,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					setTimeout(() => {
						let hasil = response.data.detailSiswa;
						let jumlah_terisi = 0;
						let jen_kel = this.listJk.filter((jk) => jk.singkatan == hasil.jenis_kelamin);
						let pen_das = this.listPd.filter((pd) => pd.singkatan == hasil.pendidikan_dasar);
						hasil.jen_kel = jen_kel.length ? jen_kel[0].keterangan : null;
						hasil.pen_das = pen_das.length ? pen_das[0].keterangan : null;
						if (hasil.foto) jumlah_terisi++;
						if (hasil.kontak) jumlah_terisi++;
						if (hasil.nisn) jumlah_terisi++;
						if (hasil.nik) jumlah_terisi++;
						if (hasil.no_kk) jumlah_terisi++;
						if (hasil.no_reg_akta_lahir) jumlah_terisi++;
						if (hasil.tempat_lahir) jumlah_terisi++;
						if (hasil.tanggal_lahir) jumlah_terisi++;
						if (hasil.penulisan_ttl) jumlah_terisi++;
						if (hasil.jumlah_saudara) jumlah_terisi++;
						if (hasil.anak_ke) jumlah_terisi++;
						if (hasil.tinggi_badan) jumlah_terisi++;
						if (hasil.berat_badan) jumlah_terisi++;
						if (hasil.lingkar_kepala) jumlah_terisi++;
						if (hasil.alamat) jumlah_terisi++;
						if (hasil.rt) jumlah_terisi++;
						if (hasil.rw) jumlah_terisi++;
						if (hasil.desa) jumlah_terisi++;
						if (hasil.kec) {
							jumlah_terisi++;
							this.getListDesa(hasil.kec);
						}
						if (hasil.kab_kota) {
							jumlah_terisi++;
							this.getListKec(hasil.kab_kota);
						}
						if (hasil.prov) {
							jumlah_terisi++;
							this.getListKabKota(hasil.prov);
						}
						if (hasil.gmaps) jumlah_terisi++;
						if (hasil.tempat_tinggal_id) jumlah_terisi++;
						if (hasil.moda_transportasi_id) jumlah_terisi++;
						if (hasil.jarak_rumah) jumlah_terisi++;
						if (hasil.waktu_tempuh) jumlah_terisi++;
						if (hasil.npsn_asal_sekolah) jumlah_terisi++;
						if (hasil.no_seri_ijazah_smp) jumlah_terisi++;
						if (hasil.pendidikan_dasar) jumlah_terisi++;
						if (typeof hasil.pernah_paud_formal == "number") jumlah_terisi++;
						if (typeof hasil.pernah_paud_nonformal == "number") jumlah_terisi++;
						if (hasil.hobi_id) jumlah_terisi++;
						if (hasil.cita_cita_id) jumlah_terisi++;
						if (hasil.foto_ijazah_smp) jumlah_terisi++;
						if (hasil.foto_kk) jumlah_terisi++;
						if (hasil.foto_akta) jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 36) * 100 + Number.EPSILON) * 100) / 100;
						this.detailSiswa = hasil;
						let memuat = this.$loading.show({
							container: this.$refs.detailSiswaCard,
							loader: "spinner",
						});
						setTimeout(() => {
							this.sedangMemuat = false;
							memuat.hide();
						}, 250);
					}, 500);
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListAgama() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/agama/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listAgama = response.data.agama;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListKebutuhanKhusus() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/berkebutuhan-khusus/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					let tidakAda = [
						{
							berkebutuhan_khusus_id: 0,
							nama: "Tidak ada",
						},
					];
					let hasil = response.data.berkebutuhan_khusus;
					this.listKebutuhanKhusus = tidakAda.concat(hasil);
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListHobi() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/hobi/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listHobi = response.data.hobi;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListCitaCita() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/cita-cita/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listCitaCita = response.data.cita_cita;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListTempatlahir() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/tempat-lahir/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listTempatLahir = response.data.tempat_lahir;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		cariAsalSekolah(VueSelect) {
			return (VueSelect.search.length !== 0 && VueSelect.open && !VueSelect.mutableLoading) || (this.listAsalSekolah.length && VueSelect.open);
		},
		async getListAsalSekolah(key) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/asal-sekolah/list",
					{
						key: String(key),
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listAsalSekolah = response.data.asal_sekolah;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListProv() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listProv = response.data.provinsi;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListKabKota(id_prov, n) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{
						wilayah_id_prov: id_prov,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					if (!n) this.listKabKotaAwal = response.data.kab_kota;
					this.listKabKota = response.data.kab_kota;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListKec(id_kab_kota, n) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{
						wilayah_id_kab_kota: id_kab_kota,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					if (!n) this.listKecAwal = response.data.kecamatan;
					this.listKec = response.data.kecamatan;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListDesa(id_kec, n) {
			try {
				const response = await this.axios.post(
					"/kesiswaan/data/wilayah/list",
					{
						wilayah_id_kec: id_kec,
					},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					if (!n) this.listDesaAwal = response.data.desa;
					this.listDesa = response.data.desa;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silahkan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListTempatTinggal() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/tempat-tinggal/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listTempatTinggal = response.data.tempat_tinggal;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async getListModTrans() {
			try {
				const response = await this.axios.post(
					"/kesiswaan/moda-transportasi/list",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					this.listModTrans = response.data.moda_transportasi;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
		async handleInputFoto(event) {
			this.sedangInput = true;
			const file = event.target.files[0];
			if (file && file.type.startsWith("image/")) {
				const maxFileSizeMB = 10;
				const maxFileSizeByte = maxFileSizeMB * 2048 * 2048;
				if (file.size > maxFileSizeByte) {
					this.sedangInput = false;
					return this.$swal({
						title: "Peringatan",
						text: `Ukuran file tidak boleh melebihi ${maxFileSizeMB}MB!`,
						icon: "warning",
						confirmButtonText: "Mengerti",
					}).then(() => {
						this.$refs.fotoInput.value = null;
						this.previewFotoInput = null;
					});
				}
				const options = {
					maxSizeMB: 2,
					maxWidthOrHeight: 2048,
					useWebWorker: true,
				};
				const compressedFile = await imageCompression(file, options);
				this.fileFoto = new File([compressedFile], "foto" + compressedFile.type.replace(/image\//, "."), { type: compressedFile.type });
				setTimeout(() => {
					this.previewFotoInput = URL.createObjectURL(compressedFile);
					this.sedangInput = false;
				}, 250);
			} else {
				this.$swal({
					title: "Peringatan",
					text: "File yang Anda pilih bukan file Gambar. Silahkan pilih jenis file yang sesuai!",
					icon: "warning",
					confirmButtonText: "Mengerti",
				}).then(() => {
					this.$refs.fotoInput.value = null;
					this.previewFotoInput = null;
					this.sedangInput = false;
				});
			}
		},
		async handleInputKk(event) {
			this.sedangInput = true;
			const file = event.target.files[0];
			if (file && file.type.startsWith("image/")) {
				const maxFileSizeMB = 10;
				const maxFileSizeByte = maxFileSizeMB * 1024 * 1024;
				if (file.size > maxFileSizeByte) {
					this.sedangInput = false;
					return this.$swal({
						title: "Peringatan",
						text: `Ukuran file tidak boleh melebihi ${maxFileSizeMB}MB!`,
						icon: "warning",
						confirmButtonText: "Mengerti",
					}).then(() => {
						this.$refs.kkInput.value = null;
						this.previewKkInput = null;
					});
				}
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1024,
					useWebWorker: true,
				};
				const compressedFile = await imageCompression(file, options);
				this.fileKk = new File([compressedFile], "foto" + compressedFile.type.replace(/image\//, "."), { type: compressedFile.type });
				setTimeout(() => {
					this.previewKkInput = URL.createObjectURL(compressedFile);
					this.sedangInput = false;
				}, 250);
			} else {
				this.$swal({
					title: "Peringatan",
					text: "File yang Anda pilih bukan file Gambar. Silahkan pilih jenis file yang sesuai!",
					icon: "warning",
					confirmButtonText: "Mengerti",
				}).then(() => {
					this.$refs.kkInput.value = null;
					this.previewKkInput = null;
					this.sedangInput = false;
				});
			}
		},
		async handleInputAkta(event) {
			this.sedangInput = true;
			const file = event.target.files[0];
			if (file && file.type.startsWith("image/")) {
				const maxFileSizeMB = 10;
				const maxFileSizeByte = maxFileSizeMB * 1024 * 1024;
				if (file.size > maxFileSizeByte) {
					this.sedangInput = false;
					return this.$swal({
						title: "Peringatan",
						text: `Ukuran file tidak boleh melebihi ${maxFileSizeMB}MB!`,
						icon: "warning",
						confirmButtonText: "Mengerti",
					}).then(() => {
						this.$refs.aktaInput.value = null;
						this.previewAktaInput = null;
					});
				}
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1024,
					useWebWorker: true,
				};
				const compressedFile = await imageCompression(file, options);
				this.fileAkta = new File([compressedFile], "foto" + compressedFile.type.replace(/image\//, "."), { type: compressedFile.type });
				setTimeout(() => {
					this.previewAktaInput = URL.createObjectURL(compressedFile);
					this.sedangInput = false;
				}, 250);
			} else {
				this.$swal({
					title: "Peringatan",
					text: "File yang Anda pilih bukan file Gambar. Silahkan pilih jenis file yang sesuai!",
					icon: "warning",
					confirmButtonText: "Mengerti",
				}).then(() => {
					this.$refs.aktaInput.value = null;
					this.previewAktaInput = null;
					this.sedangInput = false;
				});
			}
		},
		async handleInputIjazah(event) {
			this.sedangInput = true;
			const file = event.target.files[0];
			if (file && file.type.startsWith("image/")) {
				const maxFileSizeMB = 10;
				const maxFileSizeByte = maxFileSizeMB * 1024 * 1024;
				if (file.size > maxFileSizeByte) {
					this.sedangInput = false;
					return this.$swal({
						title: "Peringatan",
						text: `Ukuran file tidak boleh melebihi ${maxFileSizeMB}MB!`,
						icon: "warning",
						confirmButtonText: "Mengerti",
					}).then(() => {
						this.$refs.ijazahInput.value = null;
						this.previewIjazahInput = null;
					});
				}
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1024,
					useWebWorker: true,
				};
				const compressedFile = await imageCompression(file, options);
				this.fileIjazah = new File([compressedFile], "foto" + compressedFile.type.replace(/image\//, "."), { type: compressedFile.type });
				setTimeout(() => {
					this.previewIjazahInput = URL.createObjectURL(compressedFile);
					this.sedangInput = false;
				}, 250);
			} else {
				this.$swal({
					title: "Peringatan",
					text: "File yang Anda pilih bukan file Gambar. Silahkan pilih jenis file yang sesuai!",
					icon: "warning",
					confirmButtonText: "Mengerti",
				}).then(() => {
					this.$refs.ijazahInput.value = null;
					this.previewIjazahInput = null;
					this.sedangInput = false;
				});
			}
		},
		async unggahFoto() {
			this.sedangUnggah = true;
			const formData = new FormData();
			formData.append("siswa_id", this.detailSiswa.siswa_id);
			formData.append("foto", this.fileFoto);
			try {
				const response = await this.axios.post("/data-siswa/detail/perbarui-foto", formData, {
					headers: { Authorization: this.token },
				});
				if (response.data) {
					let jumlah_terisi = Math.round((this.persentase / 100) * 36);
					if (!this.detailSiswa.foto) {
						jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 36) * 100 + Number.EPSILON) * 100) / 100;
					}
					this.$swal({
						title: "Sukses",
						text: response.data.message,
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					this.$swal({
						title: "Gagal",
						text: "Internal Server Error",
						icon: "error",
						confirmButtonText: "Baik",
					});
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$router.go();
						});
					} else if (err.response.status == 403) {
						this.$swal({
							title: "Gagal",
							text: "Akses tidak diizinkan.",
							icon: "error",
							confirmButtonText: "Baik",
						});
					} else {
						this.$swal({
							title: "Gagal",
							html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
							icon: "error",
							confirmButtonText: "Baik",
						});
					}
				} else {
					console.log(err);
				}
			}
			setTimeout(() => {
				this.previewFoto = URL.createObjectURL(this.fileFoto);
				this.sedangUnggah = false;
				document.getElementById("tutupFormFoto").click();
			}, 1000);
		},
		async unggahKk() {
			this.sedangUnggah = true;
			const formData = new FormData();
			formData.append("siswa_id", this.detailSiswa.siswa_id);
			formData.append("detail_siswa_id", this.detailSiswa.detail_siswa_id);
			formData.append("foto_kk", this.fileKk);
			try {
				const response = await this.axios.post("/data-siswa/detail/perbarui-kk", formData, {
					headers: { Authorization: this.token },
				});
				if (response.data) {
					let jumlah_terisi = Math.round((this.persentase / 100) * 36);
					if (!this.detailSiswa.foto_kk) {
						jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 36) * 100 + Number.EPSILON) * 100) / 100;
					}
					if (response.data.detail_siswa_id) {
						this.detailSiswa.detail_siswa_id = response.data.detail_siswa_id;
					}
					this.$swal({
						title: "Sukses",
						text: response.data.message,
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					this.$swal({
						title: "Gagal",
						text: "Internal Server Error",
						icon: "error",
						confirmButtonText: "Baik",
					});
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$router.go();
						});
					} else if (err.response.status == 403) {
						this.$swal({
							title: "Gagal",
							text: "Akses tidak diizinkan.",
							icon: "error",
							confirmButtonText: "Baik",
						});
					} else {
						this.$swal({
							title: "Gagal",
							html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
							icon: "error",
							confirmButtonText: "Baik",
						});
					}
				} else {
					console.log(err);
				}
			}
			setTimeout(() => {
				this.previewKk = URL.createObjectURL(this.fileKk);
				this.sedangUnggah = false;
				document.getElementById("tutupFormKk").click();
			}, 1000);
		},
		async unggahAkta() {
			this.sedangUnggah = true;
			const formData = new FormData();
			formData.append("siswa_id", this.detailSiswa.siswa_id);
			formData.append("detail_siswa_id", this.detailSiswa.detail_siswa_id);
			formData.append("foto_akta", this.fileAkta);
			try {
				const response = await this.axios.post("/data-siswa/detail/perbarui-akta", formData, {
					headers: { Authorization: this.token },
				});
				if (response.data) {
					let jumlah_terisi = Math.round((this.persentase / 100) * 36);
					if (!this.detailSiswa.foto_akta) {
						jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 36) * 100 + Number.EPSILON) * 100) / 100;
					}
					if (response.data.detail_siswa_id) {
						this.detailSiswa.detail_siswa_id = response.data.detail_siswa_id;
					}
					this.$swal({
						title: "Sukses",
						text: response.data.message,
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					this.$swal({
						title: "Gagal",
						text: "Internal Server Error",
						icon: "error",
						confirmButtonText: "Baik",
					});
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$router.go();
						});
					} else if (err.response.status == 403) {
						this.$swal({
							title: "Gagal",
							text: "Akses tidak diizinkan.",
							icon: "error",
							confirmButtonText: "Baik",
						});
					} else {
						this.$swal({
							title: "Gagal",
							html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
							icon: "error",
							confirmButtonText: "Baik",
						});
					}
				} else {
					console.log(err);
				}
			}
			setTimeout(() => {
				this.previewAkta = URL.createObjectURL(this.fileAkta);
				this.sedangUnggah = false;
				document.getElementById("tutupFormAkta").click();
			}, 1000);
		},
		async unggahIjazah() {
			this.sedangUnggah = true;
			const formData = new FormData();
			formData.append("siswa_id", this.detailSiswa.siswa_id);
			formData.append("detail_siswa_id", this.detailSiswa.detail_siswa_id);
			formData.append("foto_ijazah", this.fileIjazah);
			try {
				const response = await this.axios.post("/data-siswa/detail/perbarui-ijazah", formData, {
					headers: { Authorization: this.token },
				});
				if (response.data) {
					let jumlah_terisi = Math.round((this.persentase / 100) * 36);
					if (!this.detailSiswa.foto_ijazah_smp) {
						jumlah_terisi++;
						this.persentase = Math.round(((jumlah_terisi / 36) * 100 + Number.EPSILON) * 100) / 100;
					}
					if (response.data.detail_siswa_id) {
						this.detailSiswa.detail_siswa_id = response.data.detail_siswa_id;
					}
					this.$swal({
						title: "Sukses",
						text: response.data.message,
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					this.$swal({
						title: "Gagal",
						text: "Internal Server Error",
						icon: "error",
						confirmButtonText: "Baik",
					});
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$router.go();
						});
					} else if (err.response.status == 403) {
						this.$swal({
							title: "Gagal",
							text: "Akses tidak diizinkan.",
							icon: "error",
							confirmButtonText: "Baik",
						});
					} else {
						this.$swal({
							title: "Gagal",
							html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
							icon: "error",
							confirmButtonText: "Baik",
						});
					}
				} else {
					console.log(err);
				}
			}
			setTimeout(() => {
				this.previewIjazah = URL.createObjectURL(this.fileIjazah);
				this.sedangUnggah = false;
				document.getElementById("tutupFormIjazah").click();
			}, 1000);
		},
		async updateData() {
			const cekUrlGmaps = new String(this.gmaps).match(/^(http:\/\/|https:\/\/)/i);
			if (this.gmaps && !cekUrlGmaps) {
				this.gmaps = "http://" + this.gmaps;
			}
			if (
				this.kontak ||
				this.agama_id ||
				this.jen_kel ||
				this.tinggi_badan ||
				this.berat_badan ||
				this.lingkar_kepala ||
				this.khusus ||
				this.hobi ||
				this.cita_cita ||
				this.nik ||
				this.no_kk ||
				this.no_reg_akta_lahir ||
				this.tempat_lahir ||
				this.tanggal_lahir ||
				this.penulisan_ttl ||
				this.anak_ke ||
				this.jumlah_saudara ||
				this.nisn ||
				this.asal_sekolah ||
				this.no_seri_ijazah_smp ||
				this.pen_das ||
				this.pernah_paud_formal ||
				this.pernah_paud_nonformal ||
				this.alamat ||
				this.rt ||
				this.rw ||
				this.desa ||
				this.kec ||
				this.kab_kota ||
				this.prov ||
				this.gmaps ||
				this.tinggal ||
				this.mod_trans ||
				this.jarak_rumah ||
				this.waktu_tempuh ||
				this.catatan !== this.detailSiswa.catatan
			) {
				this.sedangSimpan = true;
				let memuat = this.$loading.show({
					container: this.$refs.detailSiswaCard,
					loader: "spinner",
				});
				const newData = {
					siswa_id: this.detailSiswa.siswa_id,
					detail_siswa_id: this.detailSiswa.detail_siswa_id,
					kontak: this.kontak,
					agama_id: this.agama_id,
					jenis_kelamin: this.jen_kel,
					tinggi_badan: this.tinggi_badan,
					berat_badan: this.berat_badan,
					lingkar_kepala: this.lingkar_kepala,
					berkebutuhan_khusus_id: Object(this.khusus).berkebutuhan_khusus_id,
					hobi_id: Object(this.hobi).hobi_id,
					cita_cita_id: Object(this.cita_cita).cita_cita_id,
					nik: this.nik,
					no_kk: this.no_kk,
					no_reg_akta_lahir: this.no_reg_akta_lahir,
					tempat_lahir: Object(this.tempat_lahir).wilayah_id,
					tanggal_lahir: this.tanggal_lahir,
					penulisan_ttl: this.penulisan_ttl,
					anak_ke: this.anak_ke,
					jumlah_saudara: this.jumlah_saudara,
					nisn: this.nisn,
					npsn_asal_sekolah: Object(this.asal_sekolah).npsn,
					no_seri_ijazah_smp: this.no_seri_ijazah_smp,
					pendidikan_dasar: this.pen_das,
					pernah_paud_formal: this.pernah_paud_formal,
					pernah_paud_nonformal: this.pernah_paud_nonformal,
					alamat: this.alamat,
					rt: this.rt,
					rw: this.rw,
					desa: Object(this.desa).wilayah_id,
					kec: Object(this.kec).wilayah_id,
					kab_kota: Object(this.kab_kota).wilayah_id,
					prov: Object(this.prov).wilayah_id,
					gmaps: this.gmaps,
					tempat_tinggal_id: Object(this.tinggal).tempat_tinggal_id,
					moda_transportasi_id: Object(this.mod_trans).moda_transportasi_id,
					jarak_rumah: this.jarak_rumah,
					waktu_tempuh: this.waktu_tempuh,
					catatan: this.catatan,
				};
				try {
					const response = await this.axios.post("/data-siswa/detail/perbarui", newData, {
						headers: { Authorization: this.token },
					});
					if (response.data) {
						this.$swal({
							title: "Sukses",
							text: response.data.message,
							icon: "success",
							showConfirmButton: false,
							timer: 1500,
						}).then(() => {
							this.kontak = null;
							this.agama_id = null;
							this.jen_kel = null;
							this.tinggi_badan = null;
							this.berat_badan = null;
							this.lingkar_kepala = null;
							this.khusus = null;
							this.hobi = null;
							this.cita_cita = null;
							this.nik = null;
							this.no_kk = null;
							this.no_reg_akta_lahir = null;
							this.tempat_lahir = null;
							this.tanggal_lahir = null;
							this.penulisan_ttl = null;
							this.anak_ke = null;
							this.jumlah_saudara = null;
							this.nisn = null;
							this.asal_sekolah = null;
							this.no_seri_ijazah_smp = null;
							this.pen_das = null;
							this.pernah_paud_formal = null;
							this.pernah_paud_nonformal = null;
							this.alamat = null;
							this.rt = null;
							this.rw = null;
							this.desa = null;
							this.kec = null;
							this.kab_kota = null;
							this.prov = null;
							this.gmaps = null;
							this.tinggal = null;
							this.mod_trans = null;
							this.jarak_rumah = null;
							this.waktu_tempuh = null;
							this.catatan = null;
							this.kab_kotaRequired = false;
							this.kecRequired = false;
							this.desaRequired = false;
						});
					}
				} catch (err) {
					if (err.response) {
						if (err.response.status == 401) {
							this.$swal({
								title: "Gagal",
								text: "Sesi berakhir. Silahkan login ulang.",
								icon: "error",
								confirmButtonText: "Baik",
							}).then(() => {
								this.$store.dispatch("logoutUser");
								window.location.href = this.$sso + "/" + this.$appId;
							});
						} else {
							console.log(err);
							this.$swal({
								title: "Gagal",
								html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
								icon: "error",
								confirmButtonText: "Baik",
							});
						}
					} else {
						console.log(err);
					}
				}
				setTimeout(() => {
					this.sedangSimpan = false;
					this.sedangEdit = false;
					this.getDetailSiswa();
					memuat.hide();
				}, 250);
			} else {
				this.$swal({
					title: "Perhatian",
					text: "Anda belum melakukan perubahan!",
					icon: "warning",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		},
	},
};
</script>

<style></style>
