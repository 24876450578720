import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import bootstrapTooltip from "./components/bootstrapTooltip";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-admin.com:3001";
// app.config.globalProperties.$auth = "http://auth.com:3000";
// app.config.globalProperties.$sso = "http://sso.com:200";
// app.config.globalProperties.$appUrl = "http://datasiswa.com:211";
// app.config.globalProperties.$appId = "e1297a87-4fff-11ef-b208-d89ef328bfa0";

// axios.defaults.baseURL = "http://backend-admin.local";
// app.config.globalProperties.$auth = "http://auth.local";
// app.config.globalProperties.$sso = "http://sso.local";
// app.config.globalProperties.$appUrl = "http://datasiswa.local";
// app.config.globalProperties.$appId = "e1298d2e-4fff-11ef-b208-d89ef328bfa0";

axios.defaults.baseURL = "https://localbackend-admin.sitepgri.com";
app.config.globalProperties.$auth = "https://localauth.sitepgri.com";
app.config.globalProperties.$sso = "https://sso.sitepgri.com";
app.config.globalProperties.$appUrl = "https://datasiswa.sitepgri.com";
app.config.globalProperties.$appId = "e1298557-4fff-11ef-b208-d89ef328bfa0";

let isAuthenticated = false;
let isForbidden = true;

router.beforeEach((to, from, next) => {
	if (isAuthenticated) {
		if (to.name === "forbidden") {
			next();
		} else if (isForbidden) {
			next("/forbidden");
		} else {
			const user = store.getters.getUserData;
			let allowed = [];
			if (to.meta.siswa) {
				if (user.role === "siswa") {
					next();
				} else {
					next("/forbidden" + to.path);
				}
			} else if (to.meta.umum) {
				next();
			} else {
				if (user.role === "siswa") {
					next("/forbidden" + to.path);
				} else {
					if (to.meta.kesiswaan) {
						allowed = ["it", "kesiswaan"];
					} else if (to.meta.bp) {
						allowed = ["it", "kesiswaan", "waka", "bp"];
					} else if (to.meta.walas) {
						allowed = ["it", "kesiswaan", "walas"];
					} else {
						allowed = ["it", "kesiswaan", "waka", "bp", "walas"];
					}
					if (
						String(user.ket)
							.split(",")
							.some((k) => {
								return allowed.includes(k);
							})
					) {
						next();
					} else {
						next("/forbidden" + to.path);
					}
				}
			}
		}
	} else {
		const token = store.getters.getToken;
		if (to.name === "setToken" && to.params.token) {
			store.dispatch("updateToken", to.params.token);
			store
				.dispatch("authenticateUser", app.config.globalProperties.$auth)
				.then(() => {
					isAuthenticated = true;
					const user = store.getters.getUserData;
					if (user.role === "siswa") {
						isForbidden = false;
						next("/");
					} else {
						let allowed = ["it", "kesiswaan", "waka", "bp", "walas"];
						if (
							String(user.ket)
								.split(",")
								.some((k) => {
									return allowed.includes(k);
								})
						) {
							isForbidden = false;
							next("/");
						} else {
							isForbidden = true;
							next("/forbidden");
						}
					}
				})
				.catch((err) => {
					isAuthenticated = false;
					isForbidden = true;
					console.error("Failed to login with token:", err);
					redirectToSSO();
				});
		} else if (token) {
			store
				.dispatch("authenticateUser", app.config.globalProperties.$auth)
				.then(() => {
					isAuthenticated = true;
					const user = store.getters.getUserData;
					if (user.role === "siswa") {
						isForbidden = false;
						if (to.meta.siswa || to.meta.umum) {
							next();
						} else {
							next("/forbidden" + to.path);
						}
					} else {
						let allowed = ["it", "kesiswaan", "waka", "bp", "walas"];
						if (
							String(user.ket)
								.split(",")
								.some((k) => {
									return allowed.includes(k);
								})
						) {
							isForbidden = false;
							if (to.meta.siswa) {
								next("/forbidden" + to.path);
							} else {
								if (to.meta.kesiswaan) {
									allowed = ["it", "kesiswaan"];
								} else if (to.meta.bp) {
									allowed = ["it", "kesiswaan", "waka", "bp"];
								} else if (to.meta.walas) {
									allowed = ["it", "kesiswaan", "walas"];
								}
								if (
									String(user.ket)
										.split(",")
										.some((k) => {
											return allowed.includes(k);
										})
								) {
									next();
								} else {
									next("/forbidden" + to.path);
								}
							}
						} else {
							isForbidden = true;
							next("/forbidden");
						}
					}
				})
				.catch((err) => {
					isAuthenticated = false;
					isForbidden = true;
					console.error("Failed to login with token:", err);
					redirectToSSO();
				});
		} else {
			redirectToSSO();
		}
	}
});

function redirectToSSO() {
	window.location.href = app.config.globalProperties.$sso + "/" + app.config.globalProperties.$appId;
}

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
	canCancel: false,
	color: "#002b80",
	backgroundColor: "#ccddff",
	opacity: 0.25,
});
app.component("v-select", vSelect);
app.directive("tooltip", bootstrapTooltip);

app.mount("#app");
