<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
    <h1 class="h2" v-if="$route.name == 'dataSiswaBp'">Data Siswa</h1>
    <h1 class="h2" v-else>Anggota Rombel</h1>
  </div>
  <div class="my-3">
    <div class="card shadow">
      <div class="card-header">
        <div class="d-flex row gap-1">
          <div v-if="rombel" class="col-auto my-auto">
            <strong>{{ rombel.nama_rombel }}</strong>
            <span class="text-body-secondary"> ~ {{ rombel.nama_walas }}</span>
          </div>
          <span class="col-auto ms-auto">
            <strong>{{ jumlahSiswa }}</strong> siswa sktif
          </span>
        </div>
      </div>
      <div class="card-body vl-parent" ref="tableDataSiswa">
        <div v-if="dataSiswa.length" class="table-responsive shadow">
          <table class="table table-sm mb-0">
            <thead class="table-secondary align-middle">
              <tr>
                <th>#</th>
                <th class="position-sticky start-0">NIS</th>
                <th>Nama</th>
                <th>Status</th>
                <th>Progres Data</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="s, index in dataSiswa" :key="s">
                <td>{{ index+=1 }}</td>
                <td class="position-sticky start-0">{{ s.nis }}</td>
                <td>{{ s.nama }}</td>
                <td>{{ s.singkatan }}</td>
                <td>
                  <div class="hstack gap-1">
                    <strong :style="'color:' + s.warna">{{ s.persen_data_siswa }}%</strong>
                    <router-link :to="$route.path + '/' + s.siswa_id + '/detail'"
                      class="d-inline-flex align-items-center ms-auto" v-tooltip="'Lihat detail'">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                        class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                        <path fill-rule="evenodd"
                          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                      </svg></router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="sedangMemuat" class="d-flex align-items-center justify-content-center gap-2">
          <div class="spinner-border border-3 text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="text-secondary d-flex align-items-baseline gap-1">
            <span>Memuat</span>
            <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else class="text-center">Data tidak ditemukan.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnggotaRombelView',
  data() {
    return {
      rombel: {},
      jumlahSiswa: 0,
      persen_total: 0,
      dataSiswa: [],
      sedangMemuat: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    token() {
      return this.$store.getters.getToken;
    },
    rombelId() {
      return this.$route.params.rombel_id
    },
    warna() {
      if (this.persen_total == 0) {
        return '#000';
      } else if (this.persen_total < 33) {
        return 'red';
      } else if (this.persen_total < 66) {
        return 'orange';
      } else if (this.persen_total < 100) {
        return '#5394fd';
      } else {
        return 'green'
      }
    }
  },
  watch: {
    rombelId() {
      this.getDataSiswaByRombel();
    }
  },
  mounted() {
    this.getDataSiswaByRombel();
  },
  beforeUnmount() { },
  methods: {
    async getDataSiswaByRombel() {
      this.rombel = {};
      this.dataSiswa = [];
      this.sedangMemuat = true;
      try {
        const response = await this.axios.post('/data-siswa/persentase-rombel', {
          rombel_id: this.$route.params.rombel_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.rombel = response.data.rombel;
          setTimeout(() => {
            this.dataSiswa = response.data.progressDataRombel;
            let jumlah = 0;
            let persen = 0;
            for (const p of this.dataSiswa) {
              if (p.persen_data_siswa == 0) {
                p.warna = '#000';
              } else if (p.persen_data_siswa < 33) {
                p.warna = 'red';
              } else if (p.persen_data_siswa < 66) {
                p.warna = 'orange';
              } else if (p.persen_data_siswa < 100) {
                p.warna = '#5394fd';
              } else {
                p.warna = 'green'
              }
              if (p.status_siswa_id == 1) {
                persen += p.persen_data_siswa;
                jumlah++;
              }
              p.persen_data_siswa = Math.round((p.persen_data_siswa + Number.EPSILON) * 100) / 100;
            }
            this.jumlahSiswa = jumlah;
            this.persen_total = Math.round((persen / jumlah + Number.EPSILON) * 100) / 100;
            let memuat = this.$loading.show({
              container: this.$refs.tableDataSiswa,
              loader: 'spinner'
            })
            setTimeout(() => {
              this.sedangMemuat = false;
              memuat.hide();
            }, 250)
          }, 500);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
  }
}
</script>

<style></style>